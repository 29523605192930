import type {Page} from '@PosterWhiteboard/page/page.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {Group, Point} from '@postermywall/fabricjs-2';

const ALIGNMENT_PADDING = 10;

export enum GroupVeticalAlignment {
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center',
}

export enum GroupHorizontalAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum AlignType {
  CENTER = 'center',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export class Alignment {
  public page: Page;

  public constructor(page: Page) {
    this.page = page;
  }

  public horizontalAlignment(horizontalAlignmentType: GroupHorizontalAlignment): void {
    const fabricObjects = this.page.activeSelection.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }

    const group = this.page.activeSelection.getActiveObject();
    if (!(group instanceof Group)) {
      return;
    }

    group.horizontalAlignment(horizontalAlignmentType);
  }

  public verticalAlignment(verticalAlignmentType: GroupVeticalAlignment): void {
    const fabricObjects = this.page.activeSelection.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }

    const group = this.page.activeSelection.getActiveObject();
    if (!(group instanceof Group)) {
      return;
    }

    group.verticalAlignment(verticalAlignmentType);
  }

  public alignItems(type: AlignType, undoable = true): void {
    const activeFabricObject = this.page.activeSelection.getActiveObject();
    if (!activeFabricObject) {
      return;
    }

    const item = this.page.items.getItemForFabricObject(activeFabricObject);
    if (item && (item.isText() || item.isSlideshow())) {
      item.restoreDefaultState();
    }

    const items = this.page.getSelectedItems();
    const isGroup = items.length > 1;
    const canvasCenter = this.page.fabricCanvas.getCenter();
    const viewCenter = activeFabricObject.getCenterPoint();
    const point = new Point({
      x: 0,
      y: 0,
    });

    switch (type) {
      case AlignType.VERTICAL:
        point.x = viewCenter.x;
        point.y = canvasCenter.top / this.page.poster.scaling.scale;
        break;
      case AlignType.HORIZONTAL:
        point.x = canvasCenter.left / this.page.poster.scaling.scale;
        point.y = viewCenter.y;
        break;
      case AlignType.CENTER:
        point.x = canvasCenter.left / this.page.poster.scaling.scale;
        point.y = canvasCenter.top / this.page.poster.scaling.scale;
        break;
      case AlignType.LEFT: {
        const c = activeFabricObject.getCornerPoints(viewCenter);
        const minX = Math.min(c.tl.x, c.tr.x, c.bl.x, c.br.x);
        point.x = viewCenter.x - minX + ALIGNMENT_PADDING;
        point.y = viewCenter.y;
        break;
      }
      case AlignType.RIGHT: {
        const c = activeFabricObject.getCornerPoints(viewCenter);
        const maxX = Math.max(c.tl.x, c.tr.x, c.bl.x, c.br.x);
        point.x = viewCenter.x + this.page.fabricCanvas.width / this.page.poster.scaling.scale - maxX - ALIGNMENT_PADDING;
        point.y = viewCenter.y;
        break;
      }
      case AlignType.TOP: {
        const c = activeFabricObject.getCornerPoints(viewCenter);
        const minY = Math.min(c.tl.y, c.tr.y, c.bl.y, c.br.y);
        point.x = viewCenter.x;
        point.y = viewCenter.y - minY + ALIGNMENT_PADDING;
        break;
      }
      case AlignType.BOTTOM: {
        const c = activeFabricObject.getCornerPoints(viewCenter);
        const maxY = Math.max(c.tl.y, c.tr.y, c.bl.y, c.br.y);
        point.x = viewCenter.x;
        point.y = viewCenter.y + this.page.fabricCanvas.height / this.page.poster.scaling.scale - maxY - ALIGNMENT_PADDING;
        break;
      }
      default:
        return;
    }

    const corner = activeFabricObject.getCornerPoints(point);
    // In case of group we are using fabric.object set() function to move the group on canvas as for now we do not
    // have a group mediator
    if (isGroup) {
      activeFabricObject.set({
        left: Math.round(corner.tl.x),
        top: Math.round(corner.tl.y),
      });
      activeFabricObject.setCoords();
      this.page.activeSelection.updateVisuals(this.page.activeSelection.getSelectedViews(), activeFabricObject as Fabric.ActiveSelection, undoable);
    } else {
      void items[0].updateFromObject(
        {
          x: Math.round(corner.tl.x),
          y: Math.round(corner.tl.y),
        },
        {undoable}
      );
    }
  }
}
