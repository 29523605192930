import {useAppSelector} from '@/hooks';

export const useLoginPageAuthType = () => {
  return useAppSelector((state) => {
    return state.loginPage.authType;
  });
};

export const useLoginPageAuthSubType = () => {
  return useAppSelector((state) => {
    return state.loginPage.authSubType;
  });
};

export const useLoginPageAuthMode = () => {
  return useAppSelector((state) => {
    return state.loginPage.authMode;
  });
};

export const useLoginPageErrorMessage = () => {
  return useAppSelector((state) => {
    return state.loginPage.errorMessage;
  });
};

export const useLoginPageShowError = () => {
  return useAppSelector((state) => {
    return state.loginPage.showError;
  });
};

export const useIsLoginToProceedMessage = () => {
  return useAppSelector((state) => {
    return state.loginPage.isLoginToProceedMessage;
  });
};

export const useLoginPageAuthButtonsDisabled = () => {
  return useAppSelector((state) => {
    return state.loginPage.areAuthButtonsDisabled;
  });
};

export const useLoginPageAnimateNotificationBanner = () => {
  return useAppSelector((state) => {
    return state.loginPage.animateNotificationBanner;
  });
};

export const useLoginPageLoginActionType = () => {
  return useAppSelector((state) => {
    return state.loginPage.loginActionType;
  });
};

export const useLoginPageConfirmLoginType = () => {
  return useAppSelector((state) => {
    return state.loginPage.confirmLoginType;
  });
};

export const useLoginPageRedirectUrl = (): string => {
  return useAppSelector((state) => {
    return state.loginPage.redirectUrl ?? '';
  });
};
