import type {ReactElement} from 'react';
import React from 'react';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import type {UploadFontAgreementPanelProps} from '@Panels/upload-font-agreement-panel/upload-font-agreement-panel';
import {useAppSelector} from '@/hooks';

export type UploadFontAgreementModalProps = UploadFontAgreementPanelProps;

const UPLOAD_FONT_AGREEMENT_PANEL = 'upload-font-agreement-modal';

export function UploadFontAgreementModal(): ReactElement | null {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.UPLOAD_FONT_AGREEMENT_MODAL];
  });
  const height = '360px';
  const width = '600px';

  return (
    <Modal modalId={MODALS_IDS.UPLOAD_FONT_AGREEMENT_MODAL} panelId={UPLOAD_FONT_AGREEMENT_PANEL} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="upload-font-agreement-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: UPLOAD_FONT_AGREEMENT_PANEL,
        }}
      />
    </Modal>
  );
}

export const openUploadFontAgreementModal = (props: UploadFontAgreementModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.UPLOAD_FONT_AGREEMENT_MODAL,
      panelProps: props,
    })
  );
};
