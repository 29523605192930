import FontFaceObserver from 'fontfaceobserver';
import {getPMWFontsVersion, getUserFacingFonts, getUserUploadedFonts, fetchLanguageScripts} from '@Components/font-selector/font-selector-thunk';
import {setFontsLoading, setLanguageScripts, setPublicFonts, setUserFonts} from '@Components/font-selector/font-selector-slice';
import {getSymmetricDifferenceBetweenArrays, objectArrayToHashmap} from '@Utils/array.util';
import {getReadBucket, getWriteBucket} from '@Utils/s3.util';

interface StateProps {
  pmwFontsVersion: number;
  pmwFonts: Array<FontData>;
  userFonts: Array<FontData>;
  fancyTextFonts: Array<FontData>;
  languageScripts: Array<FontScript>;
  areFontsLoading: boolean;
  selectedFont: SelectedFontProps;
}

interface SelectedFontProps {
  idfont: number;
  name: string;
  fontFamily: string;
  isInternalFont: boolean;
  fontItemSelectedState: number;
}

export enum FONT_VARIATION {
  BOLD = 'bold',
  ITALIC = 'italic',
  BOLD_ITALIC = 'bolditalic',
}

export interface FontData {
  category: Array<string>;
  fontFamily: string;
  idfont: number;
  isHidden: boolean;
  name: string;
  script: Array<string>;
  uploadedOn: string;
  variation: Array<FONT_VARIATION>;
}

export interface FontCategory {
  idfontcategory: number;
  name: string;
}

export interface FontScript {
  name: string;
  recommendedFont: string;
  displayName: string;
  charCode: Array<FontScriptCharCode>;
}

interface FontScriptCharCode {
  start: number;
  end: number;
}

const TIMESTAMP_FONT_STYLESHEET = 'ts=999999999999';
const TIMESTAMP_FONT_PREVIEW_IMAGE = 'ts=99';
const FONT_STYLE_EXTENSION = '.css';
const FONT_PREVIEW_IMAGE_EXTENSION = '.png';
const SHORTENED_PREVIEW_IMAGE_VARIANT = '_short';
const FONT_RESOURCE_FILE_PATH_START = 'fonts/';
const DEFAULT_FONT_FAMILY = 'RalewayRegular';
const FONT_FAMILY_KEY = 'fontFamily';
export const BULLETS_FONT_FAMILY = 'bullets';
export const USER_FONT_LICENSE = 'user';
export const BULLETS_CSS_URL = `${window.PMW.util.asset_url('css/bullets.css')}`;

const FONT_CACHE_KEYS = {
  KEY_PMW_FONTS: 'pmw_fonts',
  KEY_PMW_FONTS_VERSION: 'pmw_fonts_version',
  KEY_FANCY_TEXT_FONTS: 'fancy_text_fonts',
  KEY_FONT_CATEGORIES: 'font_categories',
  KEY_LANGUAGE_SCRIPTS: 'font_language_scripts',
};

export enum FONT_LOAD_STATUS {
  REQUIRED = 1,
  LOADED = 2,
}

export const fontsRequestedMap: Record<string, number> = {};

export let fontsDataMap: Record<string, FontData> = {};
export let fontScriptsMap: Record<string, FontScript> = {};

export const initFontsMap = async (pmwFontsVersion: number | null = null) => {
  if (Object.keys(fontsDataMap).length === 0) {
    if (pmwFontsVersion === null) {
      pmwFontsVersion = await getPmwFontsVersion();
    }

    const pmwFonts: Array<FontData> = window.PMW.getVersionedCacheItem(FONT_CACHE_KEYS.KEY_PMW_FONTS, FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);

    fontsDataMap = {};
    pmwFonts.forEach((font) => {
      fontsDataMap[font.fontFamily] = font;
    });
  }
};

export const initLanguageScripts = async (pmwFontsVersion: number | null = null): Promise<void> => {
  if (Object.keys(fontScriptsMap).length === 0) {
    if (pmwFontsVersion === null) {
      pmwFontsVersion = await getPmwFontsVersion();
    }

    const fontScripts: Array<FontScript> = window.PMW.getVersionedCacheItem(FONT_CACHE_KEYS.KEY_LANGUAGE_SCRIPTS, FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);

    fontScriptsMap = {};
    fontScripts.forEach((fontScript) => {
      const key = fontScript.name.toLowerCase();
      fontScriptsMap[key] = fontScript;
    });
  }
};

export const getFontFamilyString = (fontFamily: string) => {
  return `'${fontFamily}'`;
};

export const isBoldVariationAvaliableForFont = (fontFamily: string) => {
  const font: FontData | undefined = fontsDataMap[fontFamily];
  return font?.variation.includes(FONT_VARIATION.BOLD);
};

export const isItalicVariationAvaliableForFont = (fontFamily: string) => {
  const font: FontData | undefined = fontsDataMap[fontFamily];
  return font?.variation.includes(FONT_VARIATION.ITALIC);
};

export const isBoldItalicVariationAvaliableForFont = (fontFamily: string) => {
  const font: FontData | undefined = fontsDataMap[fontFamily];
  return font?.variation.includes(FONT_VARIATION.BOLD_ITALIC);
};

export const getFontFamilyNameForVariations = (fontFamily: string, isBold = false, isItalic = false): string => {
  if (!isBold && !isItalic) {
    return fontFamily;
  }
  if (isBold && isItalic && isBoldItalicVariationAvaliableForFont(fontFamily)) {
    return `${fontFamily}_${FONT_VARIATION.BOLD_ITALIC}`;
  }
  if (isBold && isBoldVariationAvaliableForFont(fontFamily)) {
    return `${fontFamily}_${FONT_VARIATION.BOLD}`;
  }
  if (isItalic && isItalicVariationAvaliableForFont(fontFamily)) {
    return `${fontFamily}_${FONT_VARIATION.ITALIC}`;
  }
  return fontFamily;
};

export const addFontsAsync = async (fontFamilies: Array<string>): Promise<void> => {
  return new Promise((resolve, reject) => {
    addFonts(
      fontFamilies,
      async () => {
        resolve();
      },
      reject
    );
  });
};

export const addFonts = (fontFamilies: Array<string>, onLoadSuccess: () => void | Promise<void>, onLoadFailure?: () => void): void => {
  const unloadedFontFamilies = filterLoadedFonts(fontFamilies);
  appendStylesheetsToDOM(unloadedFontFamilies);

  if (unloadedFontFamilies.length > 0) {
    loadFonts(unloadedFontFamilies, {
      onFontLoadSuccess: appendFontToBuffer,
      onFontLoadFailure: updateFontInactiveStatus,
      onLoadSuccess,
      onLoadFailure,
    });
  } else {
    void onLoadSuccess();
  }
};

export const loadBulletsFont = (fontFamily: string, onLoadSuccess: () => void): void => {
  if (isFontLoaded(fontFamily)) {
    onLoadSuccess();
    return;
  }
  appendFontStyleSheetToDOM(fontFamily, BULLETS_CSS_URL);
  addBulletFont({onFontLoadSuccess: appendFontToBuffer, onFontLoadFailure: updateFontInactiveStatus, onLoadSuccess});
};

export const appendFontToBuffer = (fontFamily: string): void => {
  /*
        Force the browser to use the font. Just loading it with WebFont does not guarantee that FabricJS
        will pick it up when it renders text.
        */
  fontsRequestedMap[fontFamily] = FONT_LOAD_STATUS.LOADED; // mark that this font has loaded
  $('#buffer').append(`<span style="font-family:'${fontFamily}'">force load</span>`);
};

const updateFontInactiveStatus = (fontFamily: string): void => {
  fontsRequestedMap[fontFamily] = FONT_LOAD_STATUS.REQUIRED;
};

export const isFontLoaded = (fontFamily: string): boolean => {
  return !!fontsRequestedMap[fontFamily] && fontsRequestedMap[fontFamily] === FONT_LOAD_STATUS.LOADED;
};

/**
 * A font can either be not selected, or it can be selected but hasn't been loaded yet.
 * The latter can be used during loading of a font in the editor or elsewhere when it gets selected
 * @type {Readonly<{SELECTED_FONT_LOADED: number, NOT_SELECTED: number, SELECTED_FONT_NOT_LOADED: number}>}
 */
export const FONT_ITEM_SELECTION_STATES = {
  NOT_SELECTED: 1,
  SELECTED_FONT_NOT_LOADED: 2,
  SELECTED_FONT_LOADED: 3,
};

interface WebFontLoadCallbacks {
  onFontLoadSuccess?: (familyName: string, fontVariation?: string) => void;
  onFontLoadFailure?: (familyName: string, fontVariation?: string) => void;
  onLoadSuccess?: () => void;
  onLoadFailure?: () => void;
}

export const loadFonts = (
  fontFamilies: Array<string>,
  callbacks: WebFontLoadCallbacks = {onFontLoadSuccess: $.noop, onFontLoadFailure: $.noop, onLoadSuccess: $.noop, onLoadFailure: $.noop}
) => {
  const fontLoadPromises = [];
  for (const fontFamily of fontFamilies) {
    fontLoadPromises.push(new FontFaceObserver(fontFamily).load(null, 20000));
  }

  // @ts-ignore the @types file of FontFaceObserver has the wrong return type (void) instead of FontFace[]
  Promise.allSettled(fontLoadPromises).then((response: Array<PromiseSettledResult<FontFace>>) => {
    const successfullyLoadedFonts: Array<string> = [];
    let unSuccessfullyLoadedFonts: Array<string> = [];

    for (const promiseResult of response) {
      if (promiseResult.status === 'fulfilled') {
        successfullyLoadedFonts.push(promiseResult.value.family);
        if (callbacks.onFontLoadSuccess) {
          callbacks.onFontLoadSuccess(promiseResult.value.family);
        }
      } else if (promiseResult.status === 'rejected') {
        console.error(promiseResult.reason);
      }
    }

    if (callbacks.onFontLoadFailure) {
      unSuccessfullyLoadedFonts = getSymmetricDifferenceBetweenArrays(fontFamilies, successfullyLoadedFonts);
      if (unSuccessfullyLoadedFonts.length > 0) {
        console.error('unSuccessfullyLoadedFonts', unSuccessfullyLoadedFonts);
      }

      for (const unSuccessfullyLoadedFont of unSuccessfullyLoadedFonts) {
        callbacks.onFontLoadFailure(unSuccessfullyLoadedFont);
      }
    }

    if (successfullyLoadedFonts.length > 0 && callbacks.onLoadSuccess) {
      callbacks.onLoadSuccess();
    } else if (callbacks.onLoadFailure) {
      callbacks.onLoadFailure();
    }
  });
};

export const appendStylesheetsToDOM = (fontFamilies: Array<string>): void => {
  for (const fontFamily of fontFamilies) {
    appendFontStyleSheetToDOM(fontFamily, getFontStyleSheetPath(fontFamily));
  }
};

const appendFontStyleSheetToDOM = (fontFamily: string, url: string): void => {
  const cssId = `fontFamily-${fontFamily}`;
  if (!document.getElementById(cssId)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    head.appendChild(link);
  }
};

export const addBulletFont = (callbacks: WebFontLoadCallbacks = {onFontLoadSuccess: $.noop, onFontLoadFailure: $.noop, onLoadSuccess: $.noop, onLoadFailure: $.noop}): void => {
  const fontLoadPromise = new FontFaceObserver(BULLETS_FONT_FAMILY);
  fontLoadPromise.load(null, 5000).then(
    () => {
      if (callbacks.onFontLoadSuccess) {
        callbacks.onFontLoadSuccess(BULLETS_FONT_FAMILY);
      }
      if (callbacks.onLoadSuccess) {
        callbacks.onLoadSuccess();
      }
    },
    (err: string) => {
      console.error(`Error loading bullets font, details: ${err}`);
      if (callbacks.onFontLoadFailure) {
        callbacks.onFontLoadFailure(BULLETS_FONT_FAMILY);
      }
      if (callbacks.onLoadSuccess) {
        callbacks.onLoadSuccess();
      }
    }
  );
};

const filterLoadedFonts = (fontFamilies: Array<string>): Array<string> => {
  return fontFamilies.filter((fontFamily: string) => {
    return !isFontLoaded(fontFamily);
  });
};

/**
 * Loads PMW and user fonts into the redux store if not already loaded
 * user fonts can be provided to the function to directly load into redux store
 * @param {number|null} pmwFontsVersion
 * @param {Array.<Object> |null} userFonts
 */
export const initFonts = async (pmwFontsVersion: number | null = null, userFonts: Array<FontData> | null = null): Promise<void> => {
  await Promise.all([loadPublicFonts(pmwFontsVersion), loadUserFonts(userFonts)]);
  // Need to convert font-selector-reducer to ts first to change "false as any" to false
  window.PMW.redux.store.dispatch(setFontsLoading(false as any));
};

export const initFontsAndCategoriesAndScript = async (pmwFontsVersion: number | null = null, userFonts: Array<FontData> | null = null): Promise<void> => {
  await initFonts(pmwFontsVersion, userFonts);
  await Promise.all([loadLanguageScripts(pmwFontsVersion), fetchFontCategories()]);
  await Promise.all([initFontsMap(pmwFontsVersion), initLanguageScripts(pmwFontsVersion)]);
};

export const loadLanguageScripts = async (pmwFontsVersion: number | null = null): Promise<void> => {
  if (areLanguageScriptsAlreadyInStore()) {
    return;
  }

  // Need to convert font-selector-reducer to ts first to change "true as any" to true
  window.PMW.redux.store.dispatch(setFontsLoading(true as any));
  if (pmwFontsVersion === null) {
    pmwFontsVersion = await getPmwFontsVersion();
  }

  const languageScripts = window.PMW.getVersionedCacheItem(FONT_CACHE_KEYS.KEY_LANGUAGE_SCRIPTS, FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);

  if (areFontsValid(languageScripts)) {
    window.PMW.redux.store.dispatch(setLanguageScripts(languageScripts));
  } else {
    const response = await window.PMW.redux.store.dispatch(fetchLanguageScripts()).unwrap();
    cacheLanguageScripts(response, pmwFontsVersion as number);
  }
};

/**
 * returns the current PMW fonts version from redux store
 * if the version is not available, it is fetched via AJAX and returned. The version is also stored into redux for further use.
 * @return {Promise<null|*>}
 */
const getPmwFontsVersion = async (): Promise<number | null> => {
  const pmwFontsVersion = getPmwFontsVersionFromStore();
  if (pmwFontsVersion) {
    return pmwFontsVersion;
  }

  const {version} = await window.PMW.redux.store.dispatch(getPMWFontsVersion()).unwrap();
  return version;
};

/**
 * gets pmw and fancy text fonts and stores them in Redux if not already loaded
 * If the fonts are available within the local storage cache, then they are retrieved via cache else through AJAX
 * @param {number|null} pmwFontsVersion
 */
export const loadPublicFonts = async (pmwFontsVersion: number | null = null): Promise<void> => {
  if (areInternalFontsAlreadyInStore()) {
    return;
  }

  // Need to convert font-selector-reducer to ts first to change "true as any" to true
  window.PMW.redux.store.dispatch(setFontsLoading(true as any));
  if (pmwFontsVersion === null) {
    pmwFontsVersion = await getPmwFontsVersion();
  }

  const pmwFonts = window.PMW.getVersionedCacheItem(FONT_CACHE_KEYS.KEY_PMW_FONTS, FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);
  const fancyTextFonts = window.PMW.getVersionedCacheItem(FONT_CACHE_KEYS.KEY_FANCY_TEXT_FONTS, FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);

  if (areFontsValid(pmwFonts) && areFontsValid(fancyTextFonts)) {
    window.PMW.redux.store.dispatch(setPublicFonts(pmwFonts, fancyTextFonts));
  } else {
    const response = await window.PMW.redux.store.dispatch(getUserFacingFonts()).unwrap();
    cacheUserFacingFonts(response.pmwTextFonts, response.fancyTextFonts, response.version);
  }
};

/**
 * loads user fonts into redux store if not already loaded.
 * @param {Array|null} userFonts
 * @return {Promise<void>}
 */
export const loadUserFonts = async (userFonts: Array<FontData> | null): Promise<void> => {
  const userFontsFromStore = getUserFontsFromStore();

  if (userFontsFromStore) {
    return;
  }

  // Need to convert font-selector-reducer to ts first to change "true as any" to true
  window.PMW.redux.store.dispatch(setFontsLoading(true as any));
  if (userFonts) {
    // Need to convert font-selector-reducer to ts first to change "userFonts as any" to userFonts
    window.PMW.redux.store.dispatch(setUserFonts(userFonts as any));
  } else {
    window.PMW.redux.store.dispatch(getUserUploadedFonts());
  }
};

/**
 * sets the provided fonts into the font selector redux slide
 * @param state The fontSelectorReact slice
 * @param {Array} pmwFonts List of PMW fonts
 * @param {Array} fancyTextFonts List of Fancy Text fonts
 */
export const setPublicFontsInState = (state: StateProps, pmwFonts: Array<FontData>, fancyTextFonts: Array<FontData>): void => {
  state.pmwFonts = pmwFonts.sort(alphabeticallySortFonts);
  state.fancyTextFonts = fancyTextFonts.sort(alphabeticallySortFonts);
  setDefaultSelectedFont(state, pmwFonts);
};

/**
 * Resets the font selector selected font state and sets RalewayRegular as the selected font
 * @param state The fontSelectorReact slice
 * @param {Array} pmwFonts
 */
export const setDefaultSelectedFont = (state: StateProps, pmwFonts: Array<FontData>): void => {
  const defaultFont = pmwFonts.filter((font) => {
    return font.fontFamily === DEFAULT_FONT_FAMILY;
  });
  if (defaultFont.length > 0) {
    state.selectedFont = {
      fontFamily: defaultFont[0].fontFamily,
      idfont: defaultFont[0].idfont,
      name: defaultFont[0].name,
      isInternalFont: true,
      fontItemSelectedState: FONT_ITEM_SELECTION_STATES.SELECTED_FONT_LOADED,
    };
  }
};

/**
 * compare function for sorting fonts alphabetically
 * @param {Object} font1
 * @param {Object} font2
 */
export const alphabeticallySortFonts = (font1: FontData, font2: FontData) => {
  if (font1.name < font2.name) {
    return -1;
  }
  if (font1.name > font2.name) {
    return 1;
  }
  return 0;
};

/**
 * checks whether the provided array of fonts are valid
 * @param {Array} fonts
 */
export const areFontsValid = (fonts: Array<FontData>) => {
  return Array.isArray(fonts);
};

export const isInternalFont = (fontFamily: string, script = '') => {
  fontFamily = getRegularFont(fontFamily);
  const fontObj = fontsDataMap[fontFamily];
  if (typeof fontObj === 'undefined') {
    return false;
  }

  if (!script) {
    return true;
  }
  return fontObj.script.includes(script.toUpperCase());
};

export const getRegularFont = (font: string): string => {
  if (fontsDataMap[font]) {
    return font;
  }
  return font.substring(0, font.lastIndexOf('_'));
};

export const getRecommendedFont = (script: string): string => {
  return fontScriptsMap[script.toLowerCase()].recommendedFont;
};

/**
 * saves the provided fonts and font version into local storage cache
 * @param {Array} pmwFonts
 * @param {Array} fancyTextFonts
 * @param {number} pmwFontsVersion
 */
const cacheUserFacingFonts = (pmwFonts: Array<FontData>, fancyTextFonts: Array<FontData>, pmwFontsVersion: number) => {
  window.PMW.setCacheItem(FONT_CACHE_KEYS.KEY_PMW_FONTS, pmwFonts);
  window.PMW.setCacheItem(FONT_CACHE_KEYS.KEY_FANCY_TEXT_FONTS, fancyTextFonts);
  window.PMW.setCacheItem(FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);
};

const cacheLanguageScripts = (languageScripts: Array<FontScript>, pmwFontsVersion: number) => {
  window.PMW.setCacheItem(FONT_CACHE_KEYS.KEY_LANGUAGE_SCRIPTS, languageScripts);
  window.PMW.setCacheItem(FONT_CACHE_KEYS.KEY_PMW_FONTS_VERSION, pmwFontsVersion);
};

const getFontStyleSheetUrl = (fontFamily: string, bucket: string) => {
  return window.PMW.util.repoURL(FONT_RESOURCE_FILE_PATH_START + fontFamily + FONT_STYLE_EXTENSION, bucket) + TIMESTAMP_FONT_STYLESHEET;
};

export const getFontStyleSheetPath = (fontFamily: string): string => {
  if (isInternalFont(fontFamily)) {
    return getFontStyleSheetUrl(fontFamily, getReadBucket());
  }
  return getFontStyleSheetUrl(fontFamily, getWriteBucket());
};

/**
 *
 * @returns {boolean}
 */
const areInternalFontsAlreadyInStore = () => {
  return getPmwFontsFromStore() !== null && getFancyTextFontsFromStore() !== null;
};

const areLanguageScriptsAlreadyInStore = () => {
  return getLanguageScriptsFromStore() !== null;
};

export const getScriptForText = (text: string): string => {
  const charCode = $.trim(text).charCodeAt(0);
  let scriptName = '';
  for (const [key, fontScript] of Object.entries(fontScriptsMap)) {
    fontScript.charCode.some((CodeRange) => {
      if (charCode >= CodeRange.start && charCode <= CodeRange.end) {
        scriptName = key;
        return true;
      }
      return false;
    });

    if (scriptName) {
      return scriptName.toLowerCase();
    }
  }
  return scriptName.toLowerCase();
};

export const getScriptDisplayName = (script: string): string => {
  return fontScriptsMap[script.toLowerCase()].displayName;
};

/**
 *
 * @param {string} fontFamily
 * @param {string} bucket a read/write bucket
 * @param {string} fontImageVariant the font image variant, can be a variant for the shortened preview as well.
 * @returns {string}
 */
export const getFontPreviewURL = (fontFamily: string, bucket: string, fontImageVariant = '') => {
  return window.PMW.util.repoURL(FONT_RESOURCE_FILE_PATH_START + fontFamily + fontImageVariant + FONT_PREVIEW_IMAGE_EXTENSION, bucket) + TIMESTAMP_FONT_PREVIEW_IMAGE;
};

/**
 *
 * @param {string} fontFamily
 * @param {string} fontImageVariant the font image variant, can be a variant for the shortened preview as well.
 * @returns {string}
 */
export const getFontPreviewURLForPmwFont = (fontFamily: string, fontImageVariant = '') => {
  return getFontPreviewURL(fontFamily, getReadBucket(), fontImageVariant);
};

/**
 *
 * @param {string} fontFamily
 * @param {string} fontImageVariant the font image variant, can be a variant for the shortened preview as well.
 * @returns {string}
 */
export const getFontPreviewURLForExternalFont = (fontFamily: string, fontImageVariant = '') => {
  return getFontPreviewURL(fontFamily, getWriteBucket(), fontImageVariant);
};

export const fetchFontLanguageScripts = async (): Promise<any> => {
  return window.PMW.readLocal('font/getFontLanguageScripts', {});
};

export const fetchFontCategories = async (): Promise<any> => {
  return window.PMW.readLocal('font/getFontCategories', {});
};

/**
 * creates and returns an Object that maps all pmw and user fonts with their fontFamily as the key
 * @returns {Object}
 */
export const getAllFontsMap = () => {
  return objectArrayToHashmap([...(getPmwFontsFromStore() || []), ...(getUserFontsFromStore() || [])], FONT_FAMILY_KEY);
};

export const getFancyTextFontsFromStore = () => {
  return getFontSelectorStore().fancyTextFonts;
};

export const getPmwFontsFromStore = () => {
  return getFontSelectorStore().pmwFonts;
};

export const getLanguageScriptsFromStore = () => {
  return getFontSelectorStore().languageScripts;
};

export const getPmwFontsVersionFromStore = () => {
  return getFontSelectorStore().pmwFontsVersion;
};

export const getUserFontsFromStore = () => {
  return getFontSelectorStore().userFonts;
};

export const getFontSelectorStore = () => {
  return window.PMW.redux.store.getState().fontSelector;
};

export const isFontSupported = (fontFamily: string): boolean => {
  const allFonts = Object.keys(getAllFontsMap());

  const currentFont = allFonts.filter((font) => {
    return font === fontFamily;
  });

  return !(!currentFont || (currentFont && currentFont.length === 0));
};
