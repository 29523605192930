import type {ReactElement} from 'react';
import React from 'react';
import type {BasePanel} from '@Components/panel/panel.types';
import {Panel, PanelHeader} from '@Components/panel';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import type { MultiSelectEventTypeFiltersProps} from '@Panels/event-filters-panel/multi-select-event-type-filters';
import {MultiSelectEventTypeFilters} from '@Panels/event-filters-panel/multi-select-event-type-filters';
import {useDefaultPanelId} from '@Panels/hooks/useDefaultPanelId';

export const EVENT_AREA_FILTERS_PANEL_ID = 'event-area-filters-panel-id';
type EventAreaFiltersPanelBase = BasePanel & MultiSelectEventTypeFiltersProps;

export interface EventAreaFiltersPanelProps extends EventAreaFiltersPanelBase {
  panelTitle: string;
}

function EventAreaFiltersPanel({panelTitle, panelId, ...props}: EventAreaFiltersPanelProps): ReactElement {
  const id = useDefaultPanelId(panelId);
  const onClose = useDefaultPanelClose(id);

  const getContent = (): ReactElement => {
    return (
      <div className="spacing-p-t-5 spacing-p-b-2 spacing-p-r-4 spacing-p-l-4 flex-v-row">
        <MultiSelectEventTypeFilters {...props} />
      </div>
    );
  };

  return <Panel panelHeader={<PanelHeader title={panelTitle} centerAlignTitle={false} onClose={onClose} />} panelContent={getContent()} />;
}

export default EventAreaFiltersPanel;
