import {updateSpellCheckState} from '@Components/spell-check/spell-check-slice';

export const SQUIGGLY_LINE_ERROR_COLOR = '#FF0000';
export const TEXT_HOVER_HIGHLIGHT = 'rgba(211, 37, 89, 0.1)';

export interface UserDictionary {
  total: number;
  dict: Array<string>;
}

const spellCheckCache: Record<string, boolean> = {};
const suggestedWordsCache: Record<string, Array<string>> = {};
let suggestWordsInProgress = false;

export const updateSpellCheckCache = (word: string, value: boolean): void => {
  spellCheckCache[word] = value;
};

export const getSpellCheckCache = (): Record<string, boolean> => {
  return spellCheckCache;
};

export const getUserDict = async (offset = 0): Promise<UserDictionary | Array<any>> => {
  const response = (await window.PMW.readLocal('spellcheck/getUserDictionary', {
    offset,
  })) as UserDictionary;
  return response || [];
};

/**
 * set the word removed from user spell check dictionary as mispelt
 * @param {string} text
 */
export const addRemovedWordInSpellCheckCache = (text: string): void => {
  spellCheckCache[text] = false;
};

/**
 * Adds word to user dictionary
 * @param {string} text
 * @returns {boolean}
 */
export const addToDictionary = async (text: string): Promise<boolean> => {
  if (window.PMW.getUserPremiumLevel()) {
    try {
      return (await window.PMW.writeLocal('spellcheck/ignoreWord', {
        text,
      })) as boolean;
    } catch (e) {
      console.error('ignore word failed');
    }
  }
  return false;
};

/**
 * Remove word from user dictionary
 * @param {string} word
 * @returns {boolean}
 */
export const removeFromUserDictionary = async (word: string): Promise<boolean> => {
  try {
    return (await window.PMW.writeLocal('spellcheck/removeFromDictionary', {
      word,
    })) as boolean;
  } catch (e) {
    throw new Error('user dictionary update failed');
  }
};

/**
 * Checks spell and returns response using pspell checker
 * @param {string} text
 */
export const checkSpell = async (text: string | Array<string>): Promise<Record<string, boolean>> => {
  const unchecked = [];
  for (const word of text) {
    if (word.length && spellCheckCache[word] === undefined) {
      unchecked.push(word);
    }
  }
  if (unchecked.length) {
    const data = (await window.PMW.readLocal('spellcheck/checkSpell', {
      text: unchecked,
      lang: window.i18next.language,
    })) as Record<string, boolean>;
    // TODO: Check if this is implemented correctly
    for (const [word, isSpelledCorrectly] of Object.entries(data)) {
      if (!spellCheckCache[word]) {
        spellCheckCache[word] = isSpelledCorrectly;
      }
    }
  }
  return spellCheckCache;
};

export const suggestWords = async (wordToCheck: string): Promise<Array<string>> => {
  if (wordToCheck in spellCheckCache && spellCheckCache[wordToCheck]) {
    return [];
  }
  if (suggestedWordsCache[wordToCheck]) {
    return suggestedWordsCache[wordToCheck];
  }
  if (getIsSpellCheckEnabledFromStore() && !suggestWordsInProgress) {
    suggestWordsInProgress = true;
    const unchecked = [];
    for (const [word, correct] of Object.entries(spellCheckCache)) {
      if (!correct && suggestedWordsCache[word] === undefined) {
        unchecked.push(word);
      }
    }
    if (unchecked.length && window.i18next.language) {
      const data = (await window.PMW.readLocal('spellcheck/suggestWords', {
        text: unchecked,
        lang: window.i18next.language,
      })) as Record<string, Array<string>>;
      for (const [word, sugestedWords] of Object.entries(data)) {
        if (!suggestedWordsCache[word] && sugestedWords) {
          suggestedWordsCache[word] = sugestedWords.slice(0, 2);
        } else {
          suggestedWordsCache[word] = [];
        }
      }
    }
    suggestWordsInProgress = false;
    return suggestedWordsCache[wordToCheck];
  }
  return [];
};

export const updateIsSpellCheckEnabledInRedux = (isSpellcheckEnabled: boolean): void => {
  window.PMW.redux.store.dispatch(updateSpellCheckState(isSpellcheckEnabled));
};

export const updateIsSpellCheckEnabled = async (isSpellcheckEnabled: boolean): Promise<void> => {
  await window.PMW.writeLocal('spellcheck/enableDisableSpellCheck', {
    enable: isSpellcheckEnabled,
  });

  updateIsSpellCheckEnabledInRedux(isSpellcheckEnabled);
  await window.posterEditor?.whiteboard?.getCurrentPage().spellCheck.checkSpellForSelectedObject();
};

export const getIsSpellCheckEnabledFromStore = (): boolean => {
  return window.PMW.redux.store.getState().spellCheck.isSpellCheckEnabled;
};
