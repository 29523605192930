import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {ShareCollectionPanelProps} from '@Panels/share-collection-panel/share-collection-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'share-collection-modal-panel';

export type ShareCollectionModalProps = ShareCollectionPanelProps;

export function ShareCollectionModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.SHARE_COLLECTION_MODAL];
  });

  const height = '330px';
  const width = '640px';

  return (
    <Modal modalId={MODALS_IDS.SHARE_COLLECTION_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="share-collection-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openShareCollectionModal = (props: ShareCollectionModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.SHARE_COLLECTION_MODAL,
      panelProps: props,
    })
  );
};
