import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {EmbedVideosEmailmakerInputPanelProps} from '@Panels/embed-videos-emailmaker-input-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'embed-videos-emailmaker-panel';
export type EmbedVideosEmailmakerInputModalProps = EmbedVideosEmailmakerInputPanelProps;

export function EmbedVideosEmailmakerInputModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.EMBED_VIDEOS_EMAILMAKER_MODAL];
  });

  const height = '265px';
  const width = '582px';

  return (
    <Modal modalId={MODALS_IDS.EMBED_VIDEOS_EMAILMAKER_MODAL} modalHeight={height} panelId={PANEL_ID} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="embed-videos-emailmaker-input-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openEmbedVideosEmailmakerModal = (props: EmbedVideosEmailmakerInputModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EMBED_VIDEOS_EMAILMAKER_MODAL,
      panelProps: props,
    })
  );
};
