import {getUserId} from '@Libraries/user.library';
import {roundPrecision, secondsToMicroseconds} from '@Utils/math.util';
import {getCreditsToChargeForVideoBackgroundRemovalForUser} from '@Utils/video.util';
import {setVideoBgRemovalCreditsCost} from './video-bg-removal-credits-cost-slice';
import type {StateProps} from './video-bg-removal-credits-cost-slice';

export const getCreditsCostForVideoBackgroundRemoval = async (videoHashedFilename: string, trimStartTime: number, trimEndTime: number): Promise<number> => {
  const userID = getUserId() as number;
  const videoBgRemovalCreditsMap = getVideoBgRemovalCreditsMapFromStore();
  const keyForMap = getHashedIdWithStartAndEndTimeString(videoHashedFilename, trimStartTime, trimEndTime);

  if (Object.keys(videoBgRemovalCreditsMap).length > 0) {
    if (keyForMap in videoBgRemovalCreditsMap[userID]) {
      return videoBgRemovalCreditsMap[userID][keyForMap];
    }
  }

  const creditsToCharge: number = await getCreditsToChargeForVideoBackgroundRemovalForUser(videoHashedFilename, trimStartTime, trimEndTime);
  updateVideoBgRemovalCredits(videoHashedFilename, trimStartTime, trimEndTime, creditsToCharge);
  return creditsToCharge;
};

export const updateVideoBgRemovalCredits = (videoHashedFilename: string, trimStartTime: number, trimEndTime: number, updatedCredits: number): void => {
  const userID = getUserId() as number;
  const keyForMap = getHashedIdWithStartAndEndTimeString(videoHashedFilename, trimStartTime, trimEndTime);

  window.PMW.redux.store.dispatch(
    setVideoBgRemovalCreditsCost({
      userId: userID,
      videoKey: keyForMap,
      credits: updatedCredits,
    })
  );
};

export const getHashedIdWithStartAndEndTimeString = (hashedFilename: string, trimStart: number, trimEnd: number): string => {
  const trimStartTime = roundPrecision(trimStart, 6);
  const trimEndTime = roundPrecision(trimEnd, 6);
  const trimStartSubstring = secondsToMicroseconds(trimStartTime);
  const trimEndSubstring = secondsToMicroseconds(trimEndTime);

  return `${hashedFilename}_${trimStartSubstring}_${trimEndSubstring}`;
};

export const getVideoBgRemovalCreditsMapFromStore = (): StateProps => {
  return window.PMW.redux.store.getState().videoBgRemovalCreditsCost;
};
