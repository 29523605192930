import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {AddFancytextColorPanelProps} from '@Panels/add-fancytext-color-panel/add-fancytext-color-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'add-fancytext-color-panel';

export type AddFancytextColorModalProps = AddFancytextColorPanelProps;

export function AddFancytextColorModal(): ReactElement {
  const height = 'fit-content';
  const maxWidth = '100%';
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.ADD_FANCYTEXT_COLOR_MODAL];
  });

  return (
    <Modal modalId={MODALS_IDS.ADD_FANCYTEXT_COLOR_MODAL} panelId={PANEL_ID} modalHeight={height} isBottomSheetNonBlocking modalMaxWidth={maxWidth} modalWidth="100%">
      <LazyPanel
        panelDirectoryName="add-fancytext-color-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openAddFancyTextColorModal = (props: AddFancytextColorModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.ADD_FANCYTEXT_COLOR_MODAL,
      panelProps: props,
    })
  );
};
