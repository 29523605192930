export const copyToClipboard = async (text: string): Promise<void> => {
  await window.PMW.copyToClipboard(text);
};

export const readFromClipboard = async (): Promise<string> => {
  return window.PMW.readFromClipboard();
};

export const isClipboardAPISupported = (): boolean => {
  return navigator.clipboard !== undefined;
};
