import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {RecordImageAndVideoPanelProps} from '@Panels/record-image-and-video-panel/record-image-and-video-panel';
import {isIOS} from 'react-device-detect';
import useWindowSize from '@Hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'record-image-and-video-panel';

export type RecordImageAndVideoModalProps = RecordImageAndVideoPanelProps;

export function RecordImageAndVideoModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.RECORD_IMAGE_AND_VIDEO_MODAL];
  });

  const {windowWidth} = useWindowSize();

  const [width, setWidth] = useState(isIOS && windowWidth > 767 ? '5000px' : '954px');
  const [height, setHeight] = useState('628px');

  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }

    if (sizeData?.width) {
      setWidth(sizeData.width);
    }
  }, [sizeData]);

  return (
    <Modal
      modalMaxHeight="100%"
      modalMaxWidth="100%"
      modalMargin={0}
      modalId={MODALS_IDS.RECORD_IMAGE_AND_VIDEO_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      modalWidth={width}
      noBorder={isIOS}
      noBorderRadius={isIOS}
    >
      <LazyPanel
        panelDirectoryName={PANEL_ID}
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openRecordImageAndVideoModal = (props: RecordImageAndVideoModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.RECORD_IMAGE_AND_VIDEO_MODAL,
      panelProps: props,
    })
  );
};
