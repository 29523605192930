import {isIOS} from 'react-device-detect';

export const getiOSVersion = (): string => {
  const {userAgent} = window.navigator;
  const iOSVersion = /iP(hone|(o|a)d)/.test(userAgent) && /OS\s([\d_]+)/.exec(userAgent);
  return iOSVersion ? iOSVersion[1].replace(/_/g, '.') : '';
};

export const isiOS17point4OrAbove = (): boolean => {
  if (!isIOS) {
    return false;
  }

  const iOSVersion = getiOSVersion();

  if (!iOSVersion) {
    return false;
  }

  const splitVersion = iOSVersion.split('.');
  const parsediOSVersion = `${splitVersion[0]}.${splitVersion[1]}`;

  return Number(parsediOSVersion) >= 17.4;
};
