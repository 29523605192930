/**
 * This file contains helper functions for download panel in download publish modal
 */

export const PRODUCT_STATUS_FREEBIE = 'freebie';
export const PRODUCT_STATUS_GENERATING = 'generating';
export const PRODUCT_STATUS_DOWNLOADABLE = 'downloadable';
export const DEFAULT_BLEED_VALUE = '0';
export const DEFAULT_BLEED_UNIT = 'inches';
export const STANDARD_BLEED_VALUE = '0.125';
export const FILE_SIZE_LARGE = 'large';
export const FILE_QUALITY_PHOTO = 'photo';
export const FILE_QUALITY_WEB = 'web';
export const FILE_FORMAT_GIF = 'gif';
export const FILE_FORMAT_MP4 = 'mp4';
export const FILE_FORMAT_PDF = 'pdf';
export const FILE_QUALITY_HIGH = 'high';
export const FILE_QUALITY_MEDIUM = 'medium';
export const FILE_QUALITY_LOW = 'low';
export const PHOTO_QUALITY_ID = 4;
export const POSTER_QUALITY_ID = 5;
export const ANALYTICS_DOWNLOAD_CATEGORY = 'Design'

/**
 * High res max size constants (Similar to what defined in cart.php)
 * @type {number}
 */
const GIF_QUALITY_LOW_MAX_SIDE = 420,
    GIF_QUALITY_MEDIUM_MAX_SIDE = 640,
    GIF_QUALITY_HIGH_MAX_SIDE = 1920,
    DESIGN_PHOTO_QUALITY_SCALE = 4,
    VIDEO_QUALITY_MEDIUM_MAX_SIDE = 720,
    VIDEO_QUALITY_HIGH_MAX_SIDE = 1920,
    IMAGE_HIGH_RES_MAX_SIDE = 10800;

export function getBleed() {
    return getDownloadPanelStore().bleed;
}

export function getSelectedFormat() {
    return getDownloadPanelStore().selectedFormat;
}

export function getSelectedFileSize() {
    return getDownloadPanelStore().fileSize;
}

export function getMarginFlagValue() {
    return getDownloadPanelStore().hasMargin;
}

export function getFormatList() {
    return getDownloadPanelStore().formatList;
}

export function getSelectedProduct() {
    return getDownloadPanelStore().selectedProduct;
}

export function getDownloadPanelStore() {
    return PMW.redux.store.getState().downloadPanel;
}

export function isPDFFormatSelected() {
    return getSelectedFormat() === FILE_FORMAT_PDF
}

/**
 * @param {string} quality
 * @param {object} dimensions
 * @param {number} dimensions.width
 * @param {number} dimensions.height
 * @returns {string}
 */
export function getDimensionsForGivenSocialMediaSizeQuality(quality, dimensions) {
    let width = dimensions.width, height = dimensions.height;

    if (quality === FILE_QUALITY_PHOTO) {
        let newDimensions = limitDimensions(width * DESIGN_PHOTO_QUALITY_SCALE, height * DESIGN_PHOTO_QUALITY_SCALE, IMAGE_HIGH_RES_MAX_SIDE, 0)
        width = newDimensions.width;
        height = newDimensions.height;
    }

    return Math.floor(width) + ' x ' + Math.floor(height);
}

/**
 *
 * @param {string} quality
 * @param {object} dimensions
 * @param {number} dimensions.width
 * @param {number} dimensions.height
 * @returns {string}
 */
export function getDimensionsForGivenPrintSizeQuality(quality, dimensions) {
    let width = dimensions.width, height = dimensions.height;

    if (quality === FILE_QUALITY_WEB) {
        const maxDimensionAllowed = 1800,
            aspectRatio = dimensions.width / dimensions.height;

        if (aspectRatio < 1) {
            height = maxDimensionAllowed;
            width = maxDimensionAllowed * aspectRatio;
        } else {
            width = maxDimensionAllowed;
            height = maxDimensionAllowed / aspectRatio;
        }
    }

    return Math.floor(width) + ' x ' + Math.floor(height);
}

/**
 * @param {string} type
 * @param {string} quality
 * @param {object} dimensions
 * @param {number} dimensions.width
 * @param {number} dimensions.height
 * @returns {string}
 */
export function getDimensionsForGivenVideoQuality(type, quality, dimensions) {
    let height = 0, width = 0
    const maxDimensionAllowed = (type === FILE_FORMAT_GIF) ? getMaxGifSizeForGivenVideoQuality(quality, dimensions) : getMaxSizeForGivenVideoQuality(quality, dimensions),
        aspectRatio = dimensions.width / dimensions.height;

    if (aspectRatio < 1) {
        height = maxDimensionAllowed;
        width = maxDimensionAllowed * aspectRatio;
    } else {
        width = maxDimensionAllowed;
        height = maxDimensionAllowed / aspectRatio;
    }

    return Math.floor(width) + ' x ' + Math.floor(height);
}

export function limitDimensions(width, height, upperLimit, lowerLimit) {
    let dims = {
        width: width,
        height: height
    };
    if (width >= height) {
        if (width > upperLimit && upperLimit !== false) {
            dims.height = (upperLimit * height) / width;
            dims.width = upperLimit;
        } else if (height < lowerLimit && lowerLimit !== false) {
            dims.width = (lowerLimit * width) / height;
            dims.height = lowerLimit;
        }
    } else {
        if (height > upperLimit && upperLimit !== false) {
            dims.width = (upperLimit * width) / height;
            dims.height = upperLimit;
        } else if (width < lowerLimit && lowerLimit !== false) {
            dims.height = (lowerLimit * height) / width;
            dims.width = lowerLimit;
        }
    }

    return dims;
}

/**
 * @param {string} quality
 * @param {object} dimensions
 * @param {number} dimensions.width
 * @param {number} dimensions.height
 * @returns {number|*}
 */
const getMaxGifSizeForGivenVideoQuality = (quality, dimensions) => {
    const defaultMaxSideSize = dimensions.width > dimensions.height ? dimensions.width : dimensions.height;
    if (quality === FILE_QUALITY_HIGH) {
        return defaultMaxSideSize;
    }

    if (quality === FILE_QUALITY_MEDIUM) {
        return GIF_QUALITY_MEDIUM_MAX_SIDE
    }

    if (quality === FILE_QUALITY_LOW) {
        return GIF_QUALITY_LOW_MAX_SIDE
    }

    // by default return the hight quality value
    return GIF_QUALITY_HIGH_MAX_SIDE
}

/**
 * @param {string} quality
 * @param {object} dimensions
 * @param {number} dimensions.width
 * @param {number} dimensions.height
 * @returns {number|*}
 */
const getMaxSizeForGivenVideoQuality = (quality, dimensions) => {

    const defaultMaxSideSize = dimensions.width > dimensions.height ? dimensions.width : dimensions.height;
    if (quality === FILE_QUALITY_HIGH) {
        return defaultMaxSideSize;
    }

    if (quality === FILE_QUALITY_MEDIUM || quality === FILE_QUALITY_LOW) {
        return VIDEO_QUALITY_MEDIUM_MAX_SIDE
    }

    return defaultMaxSideSize
}


