import type {StackFrame} from 'stacktrace-js';
import StackTrace from 'stacktrace-js';

interface ClientLogDataError {
  error: unknown;
  addiontionalData: Record<string, any>;
}

interface ClientLogData {
  currentUrl: string;
  data: ClientLogDataError;
  stack?: Array<string>;
}

export const logClientError = async (error: unknown, addiontionalData: Record<string, any> = {}): Promise<void> => {
  const data: ClientLogData = {
    currentUrl: window.location.href,
    data: {
      error: error instanceof Error ? error.message : error,
      addiontionalData,
    },
  };

  if (error instanceof Error) {
    data.stack = stackFramesToArrayString(await StackTrace.fromError(error));
  }

  console.error(data);
  await window.PMW.writeLocal('logerror/logClientJson', data);
};

const stackFramesToArrayString = (stackFrames: StackFrame[]): Array<string> => {
  return stackFrames.map((sf) => {
    return sf.toString();
  });
};
