import {v4 as uuidv4} from 'uuid';

export enum LetterCase {
  SENTENCE_CASE = 'SENTENCE_CASE',
  UPPER_CASE = 'UPPER_CASE',
  LOWER_CASE = 'LOWER_CASE',
}

/**
 * Check if the string already exist, returns a valid unique string if the string is already taken
 * @param {string} string the string that we want to check duplication for
 * @param {Object} stringMap map of all the strings to compare the given string
 * @returns {string}
 */
export const makeStringUnique = (string: string, stringMap: Record<string, any>): string => {
  let newString = string;
  for (let i = 0; i <= Object.keys(stringMap).length; i++) {
    if (i > 0) {
      newString = `${string} (${i.toString()})`;
    }
    if (!stringMap[newString]) {
      break;
    }
  }
  return newString;
};

export const applyLetterCase = (str: string, letterCase: LetterCase): string => {
  switch (letterCase) {
    case LetterCase.SENTENCE_CASE:
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    case LetterCase.UPPER_CASE:
      return str.toUpperCase();
    case LetterCase.LOWER_CASE:
      return str.toLowerCase();
    default:
      throw new Error(`Unhandled letterCase`);
  }
};

export const padString = (input: string, paddingString: string, totalOutputLength: number): string => {
  return (Array(totalOutputLength).join(paddingString) + input).slice(-totalOutputLength);
};

export const incrementVersionInString = (string: string, maxLength?: number): string => {
  let versionString = '';
  let newString = string;

  if (doesVersionNumberExistAtEndOfString(newString)) {
    for (let i = newString.length - 1; i >= 0; i--) {
      if (newString[i] === '(') {
        const copyNumber = parseInt(newString.substring(i + 1, newString.length - 1), 10) + 1;
        versionString = ` (${copyNumber})`;
        newString = newString.substring(0, i).trim();
        break;
      }
    }
  } else {
    versionString = ' (1)';
  }

  if (maxLength) {
    return `${newString.substring(0, maxLength - versionString.length)}${versionString}`;
  }
  return `${newString}${versionString}`;
};

export const capitalize = (string: string): string => {
  return string.substr(0, 1).toUpperCase() + string.substr(1).toLowerCase();
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Returns a rfc4122 version 4 compliant UUID.
 * TODO: Maybe we should explore the built in crypto.randomUUID() function instead of the getUniqueString() function. link: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 * @returns {String}
 */
export const getUniqueString = (): string => {
  return uuidv4();
};

export const escapeHTML = (stringToEscape: string): string => {
  if (stringToEscape === null || stringToEscape === '') {
    return '';
  }

  return stringToEscape.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\"/g, '&quot;').replace(/\'/g, '&#39;').replace(/\//g, '&#x2F;');
};

export const isValidEmail = (email: string): boolean => {
  return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test(
    email
  );
};

export const hexToAscii = (hex: string): string => {
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substring(n, n + 2), 16));
  }
  return str;
};

/**
 * checks if the string has alphanumerics only (A-Z and 0-9)
 */
export const hasAlphaNumericsOnly = (string: string): boolean => {
  const regexExpression = /[^A-Z0-9]/gi;
  const matches = string.match(regexExpression);
  return !(matches && matches.length > 0);
};

const doesVersionNumberExistAtEndOfString = (string: string): boolean => {
  // the regex expression below checks for (i) at the end of the string. {i} has to be an integer
  const regex = /\([0-9]+\)$/;
  return regex.test(string);
};

export const getHeightFromPixelHeight = (height: string): number => {
  return Number(height.replace('px', ''));
};

export const isJson = (data: string): boolean => {
  try {
    JSON.parse(data);
    return true;
  } catch {
    return false;
  }
};

export const getCamelCasedString = (input: string, delimiter: string = '_'): string => {
  if (!input) {
    return '';
  }

  const words = input.split(delimiter);
  if (words.length === 1) {
    return words[0].toLowerCase();
  }

  return (
    words[0].toLowerCase() +
    words
      .slice(1)
      .map((word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('')
  );
};

export const getSeparatedStringFromCamelCase = (input: string, newCase: LetterCase = LetterCase.SENTENCE_CASE, delimiter: string = ' '): string => {
  if (!input) {
    return input;
  }

  let result = input.replace(/([A-Z])/g, `${delimiter}$1`);
  switch (newCase) {
    case LetterCase.SENTENCE_CASE:
      result = result.toLowerCase();
      return result.charAt(0).toUpperCase() + result.slice(1);
    case LetterCase.LOWER_CASE:
      return result.toLowerCase();
    case LetterCase.UPPER_CASE:
      return result.toUpperCase();
    default:
      return result;
  }
};
