import {TextHorizontalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import {colorToRGB, hexToRgb} from '@Utils/color.util';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {AuraType} from '@PosterWhiteboard/classes/item-aura.class';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {DeepPartial} from '@/global';

export enum TemplateStyleType {
  TEMPLATE_STYLE_ONE = 1,
  TEMPLATE_STYLE_TWO = 2,
  TEMPLATE_STYLE_THREE = 3,
  TEMPLATE_STYLE_FOUR = 4,
  TEMPLATE_STYLE_FIVE = 5,
  TEMPLATE_STYLE_SIX = 6,
  TEMPLATE_STYLE_SEVEN = 7,
  TEMPLATE_STYLE_EIGHT = 8,
  TEMPLATE_STYLE_NINE = 9,
  TEMPLATE_STYLE_TEN = 10,
  TEMPLATE_STYLE_ELEVEN = 11,
  TEMPLATE_STYLE_TWELVE = 12,
}

export const getDefaultTranscriptLayoutProperties = (): DeepPartial<SubtitleObject> => {
  return {
    textStyles: {
      textAlign: TextHorizontalAlignType.CENTER,
      stroke: false,
    },
    backgroundFill: {
      fillType: FillTypes.NONE,
    },
    backgroundBorderRadius: 0,
    aura: {
      type: AuraType.NONE,
    },
  };
};

export const TEMPLATE_STYLES: Record<number, DeepPartial<SubtitleObject>> = {
  [TemplateStyleType.TEMPLATE_STYLE_ONE]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('000')],
      },
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_TWO]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties(),
      fill: {
        fillColor: [hexToRgb('ffffff')],
      },
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_THREE]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      stroke: true,
      strokeColor: [0, 0, 0],
      strokeWidth: 0.5,
      fill: {
        fillColor: [hexToRgb('ffffff')],
      },
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_FOUR]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      stroke: true,
      strokeColor: [255, 255, 255],
      strokeWidth: 0.5,
      fill: {
        fillColor: [hexToRgb('000')],
      },
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_FIVE]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      stroke: true,
      strokeColor: [0, 0, 0],
      strokeWidth: 0.5,
      fill: {
        fillColor: [colorToRGB([250, 221, 85])],
      },
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_SIX]: {
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('ffffff')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.SOLID,
      fillColor: [colorToRGB([0, 0, 0])],
      fillAlpha: 0.57,
    },
    backgroundBorderRadius: 30,
  },
  [TemplateStyleType.TEMPLATE_STYLE_SEVEN]: {
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('000')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.SOLID,
      fillColor: [colorToRGB([184, 184, 184])],
      fillAlpha: 0.6,
    },
    backgroundBorderRadius: 30,
  },
  [TemplateStyleType.TEMPLATE_STYLE_EIGHT]: {
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('000')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.SOLID,
      fillColor: [colorToRGB([250, 221, 85])],
      fillAlpha: 1,
    },
    backgroundBorderRadius: 30,
  },
  [TemplateStyleType.TEMPLATE_STYLE_NINE]: {
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('ffffff')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.SOLID,
      fillColor: [colorToRGB([0, 0, 0])],
      fillAlpha: 1,
    },
    backgroundBorderRadius: 30,
  },
  [TemplateStyleType.TEMPLATE_STYLE_TEN]: {
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('000')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.SOLID,
      fillColor: [hexToRgb('ffffff')],
      fillAlpha: 1,
    },
    backgroundBorderRadius: 30,
  },
  [TemplateStyleType.TEMPLATE_STYLE_ELEVEN]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      stroke: true,
      strokeColor: [69, 22, 162],
      strokeWidth: 0.5,
      fill: {
        fillColor: [colorToRGB([250, 221, 85])],
      },
    },
    backgroundFill: {
      fillType: FillTypes.NONE,
    },
  },
  [TemplateStyleType.TEMPLATE_STYLE_TWELVE]: {
    ...getDefaultTranscriptLayoutProperties(),
    textStyles: {
      ...getDefaultTranscriptLayoutProperties().textStyles,
      fill: {
        fillColor: [hexToRgb('ffffff')],
      },
    },
    backgroundFill: {
      fillType: FillTypes.NONE,
    },
    aura: {
      dropShadowColor: colorToRGB([0, 0, 0]),
      dropShadowAlpha: 1,
      dropShadowAngle: 51,
      glowColor: colorToRGB([0, 0, 0]),
      type: AuraType.CUSTOM_SHADOW,
    },
  },
};
