import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type { EventAreaFiltersPanelProps} from '@Panels/event-area-filters-panel';
import {EVENT_AREA_FILTERS_PANEL_ID} from '@Panels/event-area-filters-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {EventType} from '@Components/content-planner/content-planner.types';
import {useEventAreaFilters} from '@Components/content-planner/content-planner.hooks';
import {useModalData} from '@Components/modal/hooks/useModalData';

type EventAreaFiltersParamsInModal = Omit<EventAreaFiltersPanelProps, 'filters' | 'onFilterChange'>;

export function EventAreaFiltersModal(): ReactElement {
  const modalData = useModalData(MODALS_IDS.EVENT_AREA_FILTERS_MODAL);

  const {filters, filtersStateSetter} = useEventAreaFilters(modalData.panelProps.filterOfEventType as EventType);

  return (
    <Modal modalId={MODALS_IDS.EVENT_AREA_FILTERS_MODAL} panelId={EVENT_AREA_FILTERS_PANEL_ID} modalHeight="auto" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="event-area-filters-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: EVENT_AREA_FILTERS_PANEL_ID,
          filters,
          onFilterChange: filtersStateSetter,
        }}
      />
    </Modal>
  );
}

export const openEventAreaFiltersModal = (filterOfEventType: EventType, panelProps: EventAreaFiltersParamsInModal): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EVENT_AREA_FILTERS_MODAL,
      panelProps: {
        filterOfEventType,
        ...panelProps,
      },
    })
  );
};
