import type {StockPanelProps} from '@Panels/stock-panel/stock-panel';
import type {ReactElement} from 'react';
import React from 'react';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import useWindowSize from '@Hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'stock-media-modal-panel';

export type StockModalProps = StockPanelProps;

export function StockModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.STOCK_MODAL];
  });
  const {windowWidth} = useWindowSize();
  const panelId = `${modalData.panelProps.panelId ?? PANEL_ID}`;

  const height = '94%';
  let width = '600px';
  if (windowWidth > 2000) {
    width = '1600px';
  } else if (windowWidth <= 2000 && windowWidth > 550) {
    width = '80%';
  }

  return (
    <Modal
      key={`${MODALS_IDS.STOCK_MODAL}-${panelId}`}
      modalId={MODALS_IDS.STOCK_MODAL}
      stickToBottom
      isBottomSheetOnMobile
      panelId={panelId}
      modalHeight={height}
      modalWidth={width}
    >
      <LazyPanel
        key={panelId}
        panelDirectoryName="stock-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId,
        }}
      />
    </Modal>
  );
}

export const openStockMediaModal = (props: StockModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.STOCK_MODAL,
      panelProps: {...props},
    })
  );
};
