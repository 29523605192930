import type {ReactElement} from 'react';
import React, { useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'poster-editor-add-slide-panel';

export function PosterEditorAddSlideModal(): ReactElement {
  const maxWidth = '600px';
  const [height, setHeight] = useState('284px');

  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }
  }, [sizeData]);

  return (
    <Modal
      modalId={MODALS_IDS.POSTER_EDITOR_ADD_SLIDESHOW_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      isBottomSheetOnMobile
      modalMaxWidth={maxWidth}
      modalWidth="100%"
    >
      <LazyPanel
        panelDirectoryName="poster-editor-add-slide-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPosterEditorAddSlideModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.POSTER_EDITOR_ADD_SLIDESHOW_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
