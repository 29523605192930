export enum DownloadPublishModalMode {
  DOWNLOAD = 'download',
  PUBLISH = 'publish',
}

export enum PublishOptions {
  PUBLISH_SHARE_LINK = 'hosted-link',
  PUBLISH_EMBED = 'embed',
  PUBLISH_SOCIAL_MEDIA = 'social-media',
}

export enum NavRightSideOptionTypes {
  BUTTON = 'button',
  ICON = 'icon',
  HIDDEN = 'hidden',
}

export enum SaveTextVariants {
  SAVE = 'save',
  SAVED = 'saved',
}
