import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export interface TeamCreditsActionProps {
  credits: number;
}

interface TeamCreditsProps {
  credits: number | null;
}

const initialState: TeamCreditsProps = {
  credits: null,
};

export const teamCreditsSlice = createSlice({
  name: 'teamCreditsCredits',
  initialState,
  reducers: {
    updateTeamCredits: (state, action: PayloadAction<TeamCreditsActionProps>) => {
      state.credits = action.payload.credits;
    },
  },
});

export const {updateTeamCredits} = teamCreditsSlice.actions;
export const teamCreditsReducer = teamCreditsSlice.reducer;
