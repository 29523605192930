import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {SocialPostSelectDesignsPanelProps} from 'src/components/panels/social-post-select-design-panel';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

export const PANEL_ID = 'social-post-select-design-panel';
export type SocialPostsSelectDesignModalProps = SocialPostSelectDesignsPanelProps;

export function SocialPostSelectDesignModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.SOCIAL_POST_SELECT_DESIGN_MODAL];
  });

  const height = 'fit-content';
  const width = '100%';
  const maxWidth = '720px';

  return (
    <Modal modalId={MODALS_IDS.SOCIAL_POST_SELECT_DESIGN_MODAL} modalMaxWidth={maxWidth} modalHeight={height} panelId={PANEL_ID} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="social-post-select-design-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openSelectDesignModal = (props: SocialPostsSelectDesignModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.SOCIAL_POST_SELECT_DESIGN_MODAL,
      panelProps: props,
    })
  );
};
