import {repoURL} from '@Libraries/s3-library';
import type {LoadSVGDataResponse} from '@Utils/fabric.util';
import {doPathsHaveSameFill, loadSVGDataFromURL} from '@Utils/fabric.util';
import {getWriteBucket} from '@Utils/s3.util';
import type {FabricObject, Group} from '@postermywall/fabricjs-2';
import {Pattern, util} from '@postermywall/fabricjs-2';

const VECTOR_MODIFIED_TIMESTAMP = 1689664600;

export enum VectorItemSource {
  GETTY = 'getty',
  PIXABAY = 'pixabay',
  STORYBLOCKS = 'storyblocks',
  STORYBLOCKS_ILLUSTRATIONS = 'storyblocks_illustrations',
  STORYBLOCKS_ILLUSTRATIONS_DASH = 'storyblocks-illustrations',
  PIXABAY_ILLUSTRATIONS = 'pixabay-illustrations',
  GETTY_ILLUSTRATIONS = 'getty-illustrations',
  PMW_SHAPES = 'pmw-shapes',
  UPLOAD = 'upload',
}

export const loadFabricObjectForShape = async (shapeName: string): Promise<FabricObject | Group> => {
  const {paths, metaData} = await loadVectorSVG(shapeName, VectorItemSource.PMW_SHAPES);

  return util.groupSVGElements(paths, {
    height: metaData.height,
    width: metaData.width,
    selectable: true,
  });
};

export const loadVectorSVG = async (vectorFileName: string, source: VectorItemSource): Promise<LoadSVGDataResponse> => {
  const url = getUrlForVector(vectorFileName, source);
  return loadSVGDataFromURL(url);
};

const getUrlForVector = (fileName: string, source: VectorItemSource): string => {
  const bucket = getWriteBucket();
  let path;

  switch (source) {
    case VectorItemSource.GETTY:
    case VectorItemSource.PIXABAY:
    case VectorItemSource.STORYBLOCKS:
    case VectorItemSource.STORYBLOCKS_ILLUSTRATIONS:
    case VectorItemSource.STORYBLOCKS_ILLUSTRATIONS_DASH:
    case VectorItemSource.PIXABAY_ILLUSTRATIONS:
    case VectorItemSource.GETTY_ILLUSTRATIONS:
    case VectorItemSource.UPLOAD:
      path = `uploads/${fileName}.svg`;
      break;
    case VectorItemSource.PMW_SHAPES:
      path = `pmwstockmedia/pmwstockshapes/${fileName}.svg`;
      break;
    default:
      throw new Error(`Unhandled source for vector filename: ${fileName}, source: ${source}`);
  }

  return `${repoURL(path, bucket)}ts=${VECTOR_MODIFIED_TIMESTAMP}`;
};

export const arePathsCustomizable = (paths: FabricObject[]): boolean => {
  if (typeof paths === 'undefined') {
    return true;
  }

  if (paths[0] && paths[0].fill && paths[0].fill instanceof Pattern) {
    return false;
  }

  return doPathsHaveSameFill(paths);
};
