import React from 'react';
import {updateErrors} from '@Components/login-page/login-page-reducer';
import type {ErrorNotificationData} from '@Components/login-page/login-page.types';
import {useAppDispatch} from '@/hooks';

export const useUpdateErrorStates = () => {
  const dispatch = useAppDispatch();

  const showErrors = (errorMessage: string) => {
    const errorData: ErrorNotificationData = {
      errorMessage,
      showError: true,
      animateNotificationBanner: true,
    };

    dispatch(updateErrors(errorData));
  };

  const hideErrors = () => {
    const errorData = {
      errorMessage: '',
      showError: false,
      animateNotificationBanner: false,
    };

    dispatch(updateErrors(errorData));
  };

  return {showErrors, hideErrors};
};
