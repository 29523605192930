import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {MoveMultimediaPanelProps} from '@Panels/move-multimedia-panel/move-multimedia-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'move-multimedia-modal-panel';

export type MoveMultimediaModalProps = MoveMultimediaPanelProps;

export function MoveMultimediaModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.MOVE_MULTIMEDIA_MODAL];
  });

  const height = '400px';
  const width = '400px';

  return (
    <Modal modalId={MODALS_IDS.MOVE_MULTIMEDIA_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width} modalMinHeight="300px" modalMinWidth="300px">
      <LazyPanel panelDirectoryName="move-multimedia-panel" panelProps={{...modalData.panelProps, panelId: PANEL_ID}} />
    </Modal>
  );
}

export const openMoveMultimediaModal = (props: MoveMultimediaModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.MOVE_MULTIMEDIA_MODAL,
      panelProps: props,
    })
  );
};
