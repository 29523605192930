import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';

const PANEL_ID = 'poster-editor-replace-audio-panel';

export function PosterEditorReplaceAudioModal(): ReactElement {
  return (
    <Modal modalId={MODALS_IDS.POSTER_EDITOR_REPLACE_AUDIO_MODAL} panelId={PANEL_ID} modalHeight="fit-content" modalMaxWidth="100%" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="poster-editor-replace-audio-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPosterEditorReplaceAudioModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.POSTER_EDITOR_REPLACE_AUDIO_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
