import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {MoreOptionsPanelProps} from '@Panels/more-options-panel/more-options-panel';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

export const PANEL_ID: string = 'more-options-modal-panel';
const DEFAULT_MIN_WIDTH = '200px';
const DEFAULT_MIN_HEIGHT = '140px';

export type MoreOptionsModalProps = MoreOptionsPanelProps;

export function MoreOptionsModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.MORE_OPTIONS_MODAL];
  });

  const panelProps = modalData.panelProps as MoreOptionsModalProps;
  return (
    <Modal
      modalId={MODALS_IDS.MORE_OPTIONS_MODAL}
      panelId={PANEL_ID}
      modalHeight="fit-content"
      modalWidth="100%"
      modalMinWidth={panelProps.minWidth ?? DEFAULT_MIN_WIDTH}
      modalMinHeight={panelProps.minHeight ?? DEFAULT_MIN_HEIGHT}
    >
      <LazyPanel
        panelDirectoryName="more-options-panel"
        panelProps={{
          ...panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openMoreOptionsModal = (props: MoreOptionsModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.MORE_OPTIONS_MODAL,
      panelProps: props,
    })
  );
};
