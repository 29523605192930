import type {ReactElement} from 'react';
import React, { useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'poster-editor-item-effects-panel';

export function PosterEditorItemEffectsModal(): ReactElement {
  const [height, setHeight] = useState('182px');
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });
  const panelProperties = useAppSelector((state) => {
    return state.panels.panelPropertiesHashmap[PANEL_ID];
  });
  const maxWidth = '800px';

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }
  }, [sizeData]);

  return (
    <Modal
      doesBottomSheetHaveSnapPoints={panelProperties?.hasSnapPoints}
      snapPointsForBottomSheet={panelProperties?.snapPointsForBottomSheet}
      modalId={MODALS_IDS.POSTER_EDITOR_ITEM_EFFECTS_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      isBottomSheetOnMobile
      isBottomSheetNonBlocking
      modalMaxWidth={maxWidth}
      modalWidth="100%"
    >
      <LazyPanel
        panelDirectoryName="poster-editor-item-effects-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPosterEditorItemEffectsModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.POSTER_EDITOR_ITEM_EFFECTS_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
