import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

export enum FoldType {
  NONE = 'NONE',
  BI_FOLD_VERTICAL = 'BI_FOLD_VERTICAL',
  TRI_FOLD_VERTICAL = 'TRI_FOLD_VERTICAL',
  FOUR_PANEL_VERTICAL = 'FOUR_PANEL_VERTICAL',
  BI_FOLD_HORIZONTAL = 'BI_FOLD_HORIZONTAL',
  TRI_FOLD_HORIZONTAL = 'TRI_FOLD_HORIZONTAL',
  FOUR_PANEL_HORIZONTAL = 'FOUR_PANEL_HORIZONTAL',
}

export const FOLD_TYPES: Array<FoldType> = [
  FoldType.NONE,
  FoldType.BI_FOLD_HORIZONTAL,
  FoldType.BI_FOLD_VERTICAL,
  FoldType.TRI_FOLD_HORIZONTAL,
  FoldType.TRI_FOLD_VERTICAL,
  FoldType.FOUR_PANEL_HORIZONTAL,
  FoldType.FOUR_PANEL_VERTICAL,
];

export interface FoldObject {
  type: FoldType;
}

export class Fold extends CommonMethods {
  private poster: Poster;
  public type: FoldType = FoldType.NONE;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public isBiFold(): boolean {
    return this.type === FoldType.BI_FOLD_VERTICAL || this.type === FoldType.BI_FOLD_HORIZONTAL;
  }

  public isTriFold(): boolean {
    return this.type === FoldType.TRI_FOLD_VERTICAL || this.type === FoldType.TRI_FOLD_HORIZONTAL;
  }

  public isFourPanelFold(): boolean {
    return this.type === FoldType.FOUR_PANEL_HORIZONTAL || this.type === FoldType.FOUR_PANEL_VERTICAL;
  }

  public toObject(): FoldObject {
    return {
      type: this.type,
    };
  }

  public updateFromObject(foldObject: Partial<FoldObject>): void {
    this.copyVals(foldObject);
    this.poster.redux.updateReduxData();
  }
}
