import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {ReplaceMediaControlItem} from '@Components/replace-media-controls/components/replace-media-control-item';
import styles from './replace-media-controls.module.scss';

interface ReplaceMediaControlsProps {
  id?: string;
  className?: string;

  onClick(option: ReplaceMediaControlsButtons): void;
  onCancel(): void;
}

export enum ReplaceMediaControlsButtons {
  EXPLORE_MEDIA = 'explore-media-option',
  MY_UPLOADS = 'my-uploads-option',
}

export function ReplaceMediaControls({id = '', className = '', ...props}: ReplaceMediaControlsProps): ReactElement {
  const getContainerClasses = (): string => {
    return [styles.container, className, 'radius-4'].join(' ');
  };

  return (
    <div id={id} className={getContainerClasses()}>
      <div className="flex-row-justify-between flex-items-center">
        <div className="flexbox flex-items-center">
          <Icon icon="icon-replace" size={IconSize.SIZE_ICON_16} />
          <Text className={`${styles.replaceText}`} size={TextSize.XSMALL} bold val={window.i18next.t('pmwjs_replace')} />
        </div>
        <Icon
          onClick={(): void => {
            props.onCancel();
          }}
          icon="icon-close"
          size={IconSize.SIZE_ICON_16}
          shape={IconShape.SQUARE}
          type={IconType.GHOST_UNCOLORED}
        />
      </div>
      <ul className={`flex-v-row ${styles.itemsContainer}`}>
        <ReplaceMediaControlItem
          onClick={(): void => {
            props.onClick(ReplaceMediaControlsButtons.MY_UPLOADS);
          }}
          icon="icon-upload-1"
          text={window.i18next.t('pmwjs_myuploads_capitalized')}
        />
        <ReplaceMediaControlItem
          onClick={(): void => {
            props.onClick(ReplaceMediaControlsButtons.EXPLORE_MEDIA);
          }}
          icon="icon-media"
          text={window.i18next.t('pmwjs_explore_media')}
        />
      </ul>
    </div>
  );
}
