import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {PANEL_ID} from '@Panels/auto-captions-mobile-panel/auto-captions-mobile-panel.types';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import {useAppSelector} from '@/hooks';

/**
 * @author Moeed Ahmad <moeeed@250mils.com>
 */
export const DEFAULT_PROMPT_BOX_HEIGHT = '300px';
export const CONTENT_SELECTED_PROMPT_BOX_HEIGHT = '350px';
export const CONTENT_GENERATED_PROMPT_BOX_HEIGHT = '500px';

export function AutoCaptionsModal(): ReactElement {
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  const height = sizeData?.height ? sizeData.height : DEFAULT_PROMPT_BOX_HEIGHT;

  return (
    <Modal
      modalId={MODALS_IDS.AUTO_CAPTIONS_MODAL}
      panelId={PANEL_ID}
      mobileHeight={height}
      modalHeight={height}
      modalWidth="100%"
      modalMaxWidth={`${SCREEN_BREAKPOINTS.SMALL}px`}
      stickToBottom
      isBottomSheetOnMobile
      isOverflowVisible
    >
      <LazyPanel
        panelDirectoryName="auto-captions-mobile-panel"
        panelProps={{
          panelId: PANEL_ID,
          modalId: MODALS_IDS.AUTO_CAPTIONS_MODAL,
          dialogMode: true,
        }}
      />
    </Modal>
  );
}

export const openAutoCaptionsMobileModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.AUTO_CAPTIONS_MODAL,
      panelProps: {
        panelId: PANEL_ID,
        modalId: MODALS_IDS.AUTO_CAPTIONS_MODAL,
        dialogMode: true,
      },
    })
  );
};
