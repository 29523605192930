import type {ReactElement} from 'react';
import React from 'react';
import './loading-toast.scss';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '@Components/circular-progress-loader';
import {hideLoadingAction} from './loading-toast-reducer';
import {ProgressBar} from './components/progress-bar';
import {useAppDispatch, useAppSelector} from '@/hooks';

export function LoadingToast(): ReactElement {
  const loadingToast = useAppSelector((state) => {
    return state.loadingToast;
  });
  const currentLoading = useAppSelector((state) => {
    return state.loadingToast.currentLoading;
  });
  const message = useAppSelector((state) => {
    return state.messageGrowl;
  });
  const isPosterVideo = useAppSelector((state) => {
    return state.posterEditor.isPosterVideo;
  });
  const isSaveReminderDisplaying = useAppSelector((state) => {
    return state.saveReminder.show;
  });
  const bottomBarHeight = useAppSelector((state) => {
    return state.posterEditorWebBottomBar.bottomBarHeight;
  });
  const dispatch = useAppDispatch();

  const hasProgress = currentLoading.progress !== undefined && currentLoading.progress !== null;
  const classes = `toast ${currentLoading.show ? 'in' : 'out'} 
        ${isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying ? '-displaying-save-reminder' : ''} 
        ${isPosterVideo || loadingToast.isProgressBarDisplaying ? '-displaying-progress-bar' : ''} 
        ${message.show ? `-displaying-message-growl` : ''} 
        ${hasProgress ? '-has-progress-bar' : ''} `;

  const getBottomForLoadingToast = (): string => {
    let bottom = 0;
    const DEFAULT_PADDING_AND_GAP = 16;
    const DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING = 76;
    const PROGRESS_BAR_HEIGHT = 64;
    const DEFAULT_SAVE_REMINDER_HEIGHT = 137;
    if (!isPosterVideo) {
      bottom = DEFAULT_PADDING_AND_GAP;
    } else {
      bottom = bottomBarHeight ? bottomBarHeight + DEFAULT_PADDING_AND_GAP : DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING;
    }

    if (loadingToast.isProgressBarDisplaying) {
      bottom += PROGRESS_BAR_HEIGHT;
    }

    if (isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying) {
      bottom += DEFAULT_SAVE_REMINDER_HEIGHT + DEFAULT_PADDING_AND_GAP;
    }

    return `${bottom}px`;
  };

  return (
    <div
      style={{
        bottom: getBottomForLoadingToast(),
      }}
      id="loading-toast"
      className={`${classes} spacing-p-r-2 spacing-p-l-2 flex-center radius-8 border-s-standard js-hide`}
    >
      <div className="content">
        {hasProgress ? <ProgressBar progress={currentLoading.progress} /> : null}
        <CircularProgressLoader classes="spacing-m-r-4 spacing-m-l-4" size={LOADER_SIZE.XSMALL} color={LOADER_COLOR.DARK} />
        <Text val={currentLoading.text} size={TextSize.XSMALL} className="content-body" />
        {!currentLoading.hideIcon ? (
          <Icon
            icon="icon-close"
            type={IconType.GHOST}
            shape={IconShape.SQUARE}
            size={IconSize.SIZE_ICON_16}
            onClick={(): void => {
              dispatch(hideLoadingAction(currentLoading.key));
            }}
          />
        ) : undefined}
      </div>
    </div>
  );
}
