import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {insert} from '@Utils/array.util';

interface UpdateLoadingProgressActionProps {
  key: string;
  progress: boolean;
}
interface UpdateLoadingTextActionProps {
  key: string;
  text: string;
}

interface Loading {
  key: string;
  priority: number;
  text: string;
  hideIcon: boolean;
  progress: boolean;
  delay: number;
  show: boolean;
}

interface LoadingToastState {
  loadings: Array<Loading>;
  currentLoading: Loading;
  isSaveReminderDisplaying: boolean;
  isProgressBarDisplaying: boolean;
}

const InitialCurrentLoading: Loading = {
  key: '',
  priority: 0,
  text: '',
  hideIcon: false,
  progress: false,
  delay: 0,
  show: false,
};

const initialState: LoadingToastState = {
  loadings: [],
  currentLoading: InitialCurrentLoading,
  isSaveReminderDisplaying: false,
  isProgressBarDisplaying: false,
};

const loadingToastSlice = createSlice({
  name: 'loadingToast',
  initialState,
  reducers: {
    showLoadingAction: (state, action: PayloadAction<Loading>) => {
      state.loadings = addLoading(state.loadings, action.payload);
      updateCurrentLoading(state);
    },
    hideLoadingAction: (state, action: PayloadAction<string>) => {
      removeLoading(state.loadings, action.payload);
      updateCurrentLoading(state);
    },
    toggleSaveReminderDisplayingAction: (state, action: PayloadAction<boolean>) => {
      state.isSaveReminderDisplaying = action.payload;
    },
    toggleProgressBarDisplayingAction: (state, action: PayloadAction<boolean>) => {
      state.isProgressBarDisplaying = action.payload;
    },
    updateLoadingProgressAction: {
      reducer: (state, action: PayloadAction<UpdateLoadingProgressActionProps>) => {
        const {key, progress} = action.payload;
        updateLoadingProgress(state.loadings, key, progress);
        updateCurrentLoading(state);
      },
      prepare: (key: string, progress: boolean) => {
        return {
          payload: {key, progress},
        };
      },
    },
    updateLoadingTextAction: {
      reducer: (state, action: PayloadAction<UpdateLoadingTextActionProps>) => {
        const {key, text} = action.payload;
        updateLoadingText(state.loadings, key, text);
        updateCurrentLoading(state);
      },
      prepare(key: string, text: string) {
        return {
          payload: {key, text},
        };
      },
    },
  },
});

export const {showLoadingAction, hideLoadingAction, updateLoadingProgressAction, updateLoadingTextAction, toggleSaveReminderDisplayingAction, toggleProgressBarDisplayingAction} =
  loadingToastSlice.actions;
export const loadingToastReducer = loadingToastSlice.reducer;

/**
 * Add the new loading to the loadings array according to its priority
 * @param {Object[]} loadings
 * @param {Object} loading
 */
function addLoading(loadings: Array<Loading>, loading: Loading): Array<Loading> {
  if (typeof loading.key === 'undefined') {
    return [];
  }

  if (loadings.length > 0) {
    const endIdx = loadings.length - 1;
    for (let i = endIdx; i >= 0; i--) {
      if (loading.priority >= loadings[i].priority) {
        return insert(loadings, i + 1, loading);
      }
    }
    return insert(loadings, 0, loading);
  }

  return insert(loadings, loadings.length, loading);
}

/**
 * Removes the loading with key from the loadings array
 * @param {Array} loadings
 * @param {String} key
 * @return {Object}
 */
function removeLoading(loadings: Array<Loading>, key: string): void {
  if (typeof key === 'undefined') {
    loadings.length = 0;
  }

  if (key && loadings.length > 0) {
    const endIdx = loadings.length - 1;

    for (let i = endIdx; i >= 0; i--) {
      if (key === loadings[i].key) {
        loadings.splice(i, 1);
      }
    }
  }
}

/**
 * Sets the current loading to the loading with the highest priority
 * @param {Object} state
 * @private
 */
function updateCurrentLoading(state: LoadingToastState): void {
  if (state.loadings.length > 0) {
    state.currentLoading = {...state.loadings[state.loadings.length - 1]};
    state.currentLoading.show = true;
  } else if (state.currentLoading) {
    state.currentLoading.show = false;
  }
}

/**
 * Sets the current loading to the loading with the highest priority
 * @param {Array} loadings
 * @param {String} loadingKey
 * @param {boolean} val
 * @private
 */
function updateLoadingProgress(loadings: Array<Loading>, loadingKey: string, val: boolean): void {
  for (let i = 0; i < loadings.length; i++) {
    if (loadings[i].key === loadingKey) {
      loadings[i].progress = val;
    }
  }
}

/**
 * Sets the current loading to the loading with the highest priority
 * @param {Array} loadings
 * @param {String} loadingKey
 * @param {string} val
 * @private
 */
function updateLoadingText(loadings: Array<Loading>, loadingKey: string, val: string): void {
  for (let i = 0; i < loadings.length; i++) {
    if (loadings[i].key === loadingKey) {
      loadings[i].text = val;
    }
  }
}
