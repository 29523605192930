import {MY_STUFF_HASHES} from '@Libraries/my-stuff-library';

export const MY_STUFF_HASH_INDEX = 1;
export const MY_STUFF_NON_REACT_CONTENT_LOADED_EVENT = 'nonReactMSContentLoaded';
export const isPageHashIncludedInList = (pageHash: string, pageHashes: Array<string>): boolean => {
  return pageHashes.length > 0 && pageHashes.includes(pageHash);
};

export const isPageRelatedToMyDesigns = (pageHash: string): boolean => {
  const myDesignsPagesList = [MY_STUFF_HASHES.myDesigns, MY_STUFF_HASHES.sharedWithMe, MY_STUFF_HASHES.search, MY_STUFF_HASHES.favorites, MY_STUFF_HASHES.recommendedForYou];

  if (!isDesignerActionCenterEnabled()) {
    myDesignsPagesList.push(MY_STUFF_HASHES.rejectedTemplates);
  }

  return isPageHashIncludedInList(pageHash, myDesignsPagesList);
};

export const isPageRelatedToMyEmails = (pageHash: string): boolean => {
  return isPageHashIncludedInList(pageHash, [
    MY_STUFF_HASHES.emailCampaigns,
    MY_STUFF_HASHES.emailCampaignSettings,
    MY_STUFF_HASHES.emailCampaignPerformance,
    MY_STUFF_HASHES.emailCampaignAudience,
  ]);
};

export const isPageRelatedToSocialMediaPublishing = (pageHash: string): boolean => {
  return isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.socialPosts, MY_STUFF_HASHES.socialAccounts]);
};

export const isContentPlannerPage = (pageHash: string): boolean => {
  return pageHash === MY_STUFF_HASHES.contentPlanner;
};

export const isClassProjectsPage = (pageHash: string): boolean => {
  return pageHash === MY_STUFF_HASHES.classProjects;
};

export const isTrashPage = (pageHash: string): boolean => {
  return isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.trash, MY_STUFF_HASHES.trashSocialPosts, MY_STUFF_HASHES.trashEmailCampaigns]);
};

export const isBrandKitsPage = (pageHash: string): boolean => {
  return pageHash === MY_STUFF_HASHES.brands;
};

export const isSocialMediaPostsPage = (pageHash: string = getMyStuffPageHash()): boolean => {
  return pageHash === MY_STUFF_HASHES.socialPosts;
};

export const isSocialMediaAccountsPage = (pageHash: string = getMyStuffPageHash()): boolean => {
  return pageHash === MY_STUFF_HASHES.socialAccounts;
};

export const isRecommendationsPage = (pageHash: string = getMyStuffPageHash()): boolean => {
  return pageHash === MY_STUFF_HASHES.recommendedForYou;
};

export const shouldRecommendationsPageBeShown = (): boolean => {
  return !!window.reactPageProps.doesUserHaveRecommendationsAvailable;
};

export const isDesignerActionCenterEnabled = (): boolean => {
  return !!window.reactPageProps.isDesignerActionCenterEnabled;
};

export const isPagePartOfActionCenter = (pageHash: string = getMyStuffPageHash()): boolean => {
  return isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.improveTemplates, MY_STUFF_HASHES.rejectedTemplates]);
};

export const isImproveTemplatesPage = (pageHash: string = getMyStuffPageHash()): boolean => {
  return pageHash === MY_STUFF_HASHES.improveTemplates;
};

export const isEmailMarketingCampaignsPage = (pageHash: string = getMyStuffPageHash()): boolean => {
  return pageHash === MY_STUFF_HASHES.emailCampaigns;
};

export const isBusinessProfilesPage = (pageHash: string): boolean => {
  return pageHash === MY_STUFF_HASHES.businessProfile;
};

export const isPageRelatedToBusinessProfiles = (pageHash: string): boolean => {
  return isPageHashIncludedInList(pageHash, [MY_STUFF_HASHES.businessProfile, MY_STUFF_HASHES.brands]);
};

/**
 * Extracts and returns the page hash from the passed window location hash, such
 * that it can be compared with one of the constants from MY_STUFF_HASHES.
 * @return {string}
 */
export const getMyStuffPageHash = (): string => {
  return `#/${window.location.hash.split('/')[MY_STUFF_HASH_INDEX]}`;
};

export const goToMyStuff = (): void => {
  window.location.href = window.PMW.util.site_url('posters/mine');
};

export const isTeamAdmin = (): boolean => {
  return !!window.reactPageProps?.isTeamAdmin;
};
