import type { AUTH_TYPE} from '@Libraries/login-signup-library';
import {AUTH_SUB_TYPE} from '@Libraries/login-signup-library';
import type {AuthTypes} from '@Components/login-page/login-page.types';
import {updateAuthTypeState} from '@Components/login-page/login-page-reducer';
import {useAppDispatch} from '@/hooks';
import {useUpdateErrorStates} from '@/hooks/login-signup/useUpdateErrorStates';

export const useUpdateAuthFormTypes = () => {
  const dispatch = useAppDispatch();
  const {hideErrors} = useUpdateErrorStates();

  const updateAuthTypes = (authType: AUTH_TYPE, authSubType: AUTH_SUB_TYPE = AUTH_SUB_TYPE.LOGIN_WITH_FORM) => {
    const updatedTypes: AuthTypes = {
      type: authType,
      subType: authSubType,
    };

    dispatch(updateAuthTypeState(updatedTypes));
  };

  const updateAuthTypesAndClearErrors = (authType: AUTH_TYPE, authSubType: AUTH_SUB_TYPE = AUTH_SUB_TYPE.LOGIN_WITH_FORM, cb: Function = $.noop) => {
    hideErrors();
    updateAuthTypes(authType, authSubType);
    cb();
  };
  return {updateAuthTypes, updateAuthTypesAndClearErrors};
};
