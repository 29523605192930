import type {ReactElement} from 'react';
import React, {useContext} from 'react';
import type {LeftSidebarBase} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {SIDEBAR_LIST_TYPE} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {Panel, PanelHeader} from '@Components/panel';
import type {BasePanel} from '@Components/panel/panel.types';
import {useDefaultPanelId} from '@Panels/hooks/useDefaultPanelId';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import {ClickableDiv} from '@Components/clickable-div';
import {useSidebarListItems} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.hooks';
import {ModalDetails} from '@Components/modal/modal-context';
import {MY_STUFF_HASHES} from '@Libraries/my-stuff-library';
import styles from './mystuff-mobile-navigation-options-panel.module.scss';

export interface MystuffMobileNavigationOptionsPanelProps extends LeftSidebarBase, BasePanel {
  primaryItemsListIndex: number;
}

export const MYSTUFF_MOBILE_NAV_PANEL_ID = 'mystuff-mobile-nav-options-panel';

export function MystuffMobileNavigationOptionsPanel({
  pageHash,
  userType,
  userPremiumLevel,
  panelId,
  primaryItemsListIndex,
}: MystuffMobileNavigationOptionsPanelProps): ReactElement | null {
  const id = useDefaultPanelId(panelId);
  const closePanel = useDefaultPanelClose(id);
  const modalDetails = useContext(ModalDetails);
  const isOpenInMobileModal = modalDetails?.isMobileModal === true;
  const {listItems: primaryList} = useSidebarListItems({
    pageHash,
    userType,
    userPremiumLevel,
    listItemClassName: 'spacing-m-t-0 spacing-m-b-4',
    listType: SIDEBAR_LIST_TYPE.PRIMARY,
  });

  const {listItems: secondaryListItems} = useSidebarListItems({
    pageHash,
    userType,
    userPremiumLevel,
    listItemClassName: 'spacing-m-t-0 spacing-m-b-4',
    listType: SIDEBAR_LIST_TYPE.SECONDARY,
    excludedPageHashes: [MY_STUFF_HASHES.businessProfile],
  });

  const {listItems: miscItems} = useSidebarListItems({
    pageHash,
    userType,
    userPremiumLevel,
    listType: SIDEBAR_LIST_TYPE.MISC,
    listItemClassName: 'spacing-m-t-0 spacing-m-b-4',
  });

  const getPrimaryList = (): ReactElement[] => {
    if (primaryItemsListIndex < 0 || primaryItemsListIndex >= primaryList.length) {
      return [];
    }

    return primaryList.slice(primaryItemsListIndex);
  };

  const primaryListItems = getPrimaryList();
  const divider = (
    <li className="_unpadded spacing-m-b-4 _full-width border-s-standard _no-border-top _no-border-left _no-border-right _unmargin-top _unmargin-left _unmargin-right" />
  );
  const getContent = (): ReactElement => {
    return (
      <ClickableDiv
        enableFocusOutline={false}
        className={`${styles.container} ${isOpenInMobileModal ? '_unpadded-top' : 'spacing-p-t-4'} _full-width spacing-p-r-4 spacing-p-l-4 _unpadded-bottom`}
        onClick={closePanel}
      >
        <ul className="_unpadded _unmargin">
          {primaryListItems}
          {primaryListItems.length > 0 ? divider : null}
          {secondaryListItems}
          {divider}
          {miscItems}
        </ul>
      </ClickableDiv>
    );
  };

  return (
    <Panel
      className={styles.panelRoot}
      panelHeader={<PanelHeader title={window.i18next.t('pmwjs_more_options')} className={`${styles.panelHeader} _unpadded-bottom`} onClose={closePanel} />}
      panelContent={getContent()}
    />
  );
}
