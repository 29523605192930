import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {LazyPanel} from '@Components/lazy-panel';

const PANEL_ID = 'replace-image-background-modal-panel';

export function ReplaceBackgroundImageModal(): ReactElement {
  return (
    <Modal modalId={MODALS_IDS.REPLACE_BACKGROUND_IMAGE_MODAL} mobileHeight="800px" isBottomSheetOnMobile panelId={PANEL_ID} modalHeight="100%" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="replace-background-image-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openReplaceBackgroundImageModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.REPLACE_BACKGROUND_IMAGE_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};
