import {getDirForMediaSizeType, MediaItemSizeType, repoURL} from '@Libraries/s3-library';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import {decompressFrames, parseGIF} from 'gifuct-js';
import {getWriteBucket} from '@Utils/s3.util';
import {getCompatibleImageFileExtension} from '@Utils/image.util';

export const UserImageSource = {
  GETTY: 'getty',
  BACKGROUND_UPLOAD: 'background_upload',
  GETTY_ILLUSTRATIONS: 'getty-illustrations',
  STORYBLOCKS_ILLUSTRATIONS: 'storyblocks-illustrations',
  PIXABAY_ILLUSTRATIONS: 'pixabay-illustrations',
  UPLOAD: 'upload',
  EMAIL_UPLOAD: 'email_upload',
  PIXABAY: 'pixabay',
  STORYBLOCKS: 'storyblocks',
  DRAWING: 'drawing',
  BRAND_LOGOS: 'brand-logos',
  AI_TEXT_TO_IMAGE: 'AI_TEXT_TO_IMAGE',
};

const REMOVED_BACKGROUND_IMAGE_EXTENSION = 'webp';
export type UserImageSourceType = (typeof UserImageSource)[keyof typeof UserImageSource];

export interface UserImageVOResponse {
  id: string;
  hasTransparency: boolean;
  uploaderName: string;
  uploaderId: string;
  ext: string;
  filename: string;
  source: string;
  src: UserImageSourceType;
  width: number;
  height: number;
}

const BASE_DIR = 'uploads';

export const getDirPathForUserImage = (sizeType: MediaItemSizeType): string => {
  const subDirForSize = getDirForMediaSizeType(sizeType);
  return `${BASE_DIR}${subDirForSize ? `/${subDirForSize}` : ''}`;
};

export const getUserImageScreenFilename = (hashedFilename: string, extension: string): string => {
  return `${hashedFilename}.${extension}`;
};

export const getRemovedImageBackgroundScreenURL = (imageHashedFilename: string): string => {
  return repoURL(`${getDirPathForUserImage(MediaItemSizeType.SCREEN)}/${getRemovedImageBackgroundFilename(imageHashedFilename)}`, getWriteBucket());
};

export const getRemovedImageBackgroundHighresURL = (imageHashedFilename: string): string => {
  return repoURL(`${getDirPathForUserImage(MediaItemSizeType.HIGH_RES)}/${getRemovedImageBackgroundFilename(imageHashedFilename)}`, getWriteBucket());
};

const getRemovedImageBackgroundFilename = (imageHashedFilename: string): string => {
  return `${imageHashedFilename}_removedbg.${getCompatibleImageFileExtension(REMOVED_BACKGROUND_IMAGE_EXTENSION)}`;
};

export const isMultiFrameGIF = (file: File): Promise<boolean> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = (event): void => {
      const buffer = event.target?.result as ArrayBufferLike;
      const gif = parseGIF(new Uint8Array(buffer));
      const frames = decompressFrames(gif, true);
      resolve(frames.length > 1);
    };

    reader.onerror = (error): void => {
      console.error('Error reading GIF file:', error);
      resolve(false);
    };

    reader.readAsArrayBuffer(file);
  });
};

/**
 * Performs pre-upload actions, checks the file mime type, gets the total number of files for displaying later
 * and updates the total upload count if an upload is already in progress
 * @param {Array} files Files to upload
 * @param uploadVectors
 * @return {Array} returns valid files
 * @override
 */
export const filterValidImageFiles = (files: Array<File>, uploadVectors = true): Array<File> => {
  let invalidFiles = 0;
  for (let i = files.length - 1; i >= 0; i--) {
    if (!isImageFileValid(files[i]) || (isVectorFileValid(files[i]) && !uploadVectors)) {
      invalidFiles += 1;
      files.splice(i, 1);
    }
  }

  if (invalidFiles === 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_invalid_image_file'),
    });
  } else if (invalidFiles > 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_all_invalid_image_files', {
        numfiles: invalidFiles,
      }),
    });
  }
  return files;
};

export const isImageFileValid = (file: File): boolean => {
  return !!file.type && file.type.indexOf('image/') !== -1;
};

export const isVectorFileValid = (file: File): boolean => {
  return file.type === 'image/svg+xml';
};

export const isGifFile = (file: File): boolean => {
  return file.type === 'image/gif';
};

export const getHashedFilenameForStockAsset = (source: UserImageSourceType, stockId: string): string => {
  return `${source}${stockId}`;
};
