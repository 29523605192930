import type {ReactElement} from 'react';
import React, { useEffect, useState} from 'react';
import {Modal} from '@Components/modal';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'poster-editor-more-options-panel';

export function PosterEditorMoreOptionsModal(): ReactElement {
  const maxWidth = '800px';
  const [height, setHeight] = useState('86px');
  const sizeData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[PANEL_ID];
  });

  useEffect((): void => {
    if (sizeData?.height) {
      setHeight(sizeData.height);
    }
  }, [sizeData]);

  return (
    <Modal
      modalId={MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL}
      panelId={PANEL_ID}
      modalHeight={height}
      mobileHeight={height}
      isBottomSheetOnMobile
      isBottomSheetNonBlocking
      modalMaxWidth={maxWidth}
      modalWidth="100%"
      disableDragToClose
      bottomSheetCloseThreshold={20}
    >
      <LazyPanel
        panelDirectoryName="poster-editor-more-options-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openPosterEditorMoreOptionsModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL,
      panelProps: {
        panelId: PANEL_ID,
      },
    })
  );
};

export const closePosterEditorMoreOptionsModal = (): void => {
  window.PMW.redux.store.dispatch(closeModal(MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL));
};
