import {createSlice} from '@reduxjs/toolkit'
import {DEFAULT_BLEED_UNIT, DEFAULT_BLEED_VALUE, FILE_SIZE_LARGE} from "@Libraries/download-panel-library";

const initialState = {
    productsList: [],
    selectedProduct: null,
    selectedFormat: '',
    formatList: [],
    hasMargin: false,
    fileSize: FILE_SIZE_LARGE,
    bleed: {
        val: DEFAULT_BLEED_VALUE,
        units: DEFAULT_BLEED_UNIT
    }
};

const downloadPanelSlice = createSlice({
    name: 'downloadPanel',
    initialState,
    reducers: {
        updateProduct(state, action) {
            state.selectedProduct = action.payload;
        },
        updateFormat(state, action) {
            state.selectedFormat = action.payload;
        },
        updateProductsList(state, action) {
            state.productsList = action.payload;
        },
        updateFormatList(state, action) {
            state.formatList = action.payload
        },
        updateMarginFlag(state, action) {
            state.hasMargin = action.payload
        },
        updateFileSize(state, action) {
            state.fileSize = action.payload
        },
        updateBleed(state, action) {
            state.bleed = action.payload
        },
        updateDownloadPanel: {
            reducer(state, action) {
            },
            prepare(key, progress) {
            }
        }
    }
})

export const {updateProduct, updateFormat, updateProductsList, updateFormatList, updateMarginFlag, updateFileSize, updateBleed} = downloadPanelSlice.actions;
export const downloadPanelReducer = downloadPanelSlice.reducer;