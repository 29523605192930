import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ImageItem, ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {VideoItem, VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {ImageSlideItem, ImageSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {ImageData, TempImageData, VideoData} from '@Libraries/add-media-library';
import {isTempImageData, ElementDataType} from '@Libraries/add-media-library';
import {prepImageItemObjectFromImageData, prepVideoItemObjectFromVideoData} from '@PosterWhiteboard/items/item/element-data-to-item.library';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {loadImageAsync} from '@Utils/image.util';
import {openCropperModal} from '@Modals/cropper-modal/cropper-modal.library';
import {getFilenameForVideo, getRepoVideoURL, USER_VIDEO_SOURCE} from '@Libraries/user-video-library';
import {loadVideo} from '@Utils/video.util';
import {openErrorModal} from '@Modals/error-modal';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {MULTI_MEDIA_UPLOAD_SOURCES} from '@Libraries/user-media-library';
import type {CropperPanelApplyResponse} from '@Panels/cropper-panel';
import {v4 as uuidv4} from 'uuid';
import type {ItemData, NewImageItemData, NewTempImageItemData} from '@PosterWhiteboard/page/add-item.class';
import {onOpenMaskingModal, openGettyImageLimitReachedMessageModal, openGettyVideoLimitReachedMessageModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {getImageScreenURL, ImageItemSource} from '@Libraries/image-item.library';
import type {MaskingShape, MaskingShapeObject} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import type {MaskingText, MaskingTextObject} from '@PosterWhiteboard/classes/masking/masking-text.class';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {DeepPartial} from '@/global';

export class ReplaceItem {
  public page: Page;

  public constructor(page: Page) {
    this.page = page;
  }

  public async replaceMedia(itemToReplaceWith: ItemData, activeItem: ImageItem | VideoItem | ImageSlideItem | VideoSlideItem): Promise<void> {
    const scaledWidth = activeItem.getScaledWidth();
    const scaledHeight = activeItem.getScaledHeight();

    if (itemToReplaceWith.type === ElementDataType.IMAGE) {
      return this.replaceWithImage(
        {
          scaledWidth,
          scaledHeight,
          maintainAspectRatioForScaledDimension: true,
          ...itemToReplaceWith,
        },
        activeItem
      );
    }
    if (itemToReplaceWith.type === ElementDataType.VIDEO) {
      return this.replaceWithVideo(
        {
          scaledWidth,
          scaledHeight,
          maintainAspectRatioForScaledDimension: true,
          ...itemToReplaceWith,
        },
        activeItem
      );
    }

    throw new Error('unexpected type received in replace media');
  }

  public async replaceMediaSlide(itemToReplaceWith: ItemData, activeSlide: ImageSlideItem | VideoSlideItem, activeItem: SlideshowItem): Promise<void> {
    if (itemToReplaceWith.type === ElementDataType.IMAGE) {
      return this.replaceSlideWithImageSlide(itemToReplaceWith, activeSlide, activeItem);
    }
    if (itemToReplaceWith.type === ElementDataType.VIDEO) {
      return this.replaceSlideWithVideoSlide(itemToReplaceWith, activeSlide, activeItem);
    }

    throw new Error('unexpected type received in replace media slide');
  }

  public async replaceSlideWithVideoSlide(item: VideoData, activeSlide: ImageSlideItem | VideoSlideItem, activeItem: SlideshowItem): Promise<void> {
    if (item.source === USER_VIDEO_SOURCE.GETTY && this.page.poster.hasMaxNumberofGettyVideoItems()) {
      if (!(activeSlide.isVideoSlide() && activeSlide.videoSource === USER_VIDEO_SOURCE.GETTY)) {
        openGettyVideoLimitReachedMessageModal();
      }
      return;
    }

    const itemToAdd: Partial<VideoSlideItem> = {};
    const uid = uuidv4();
    itemToAdd.hashedFilename = item.hashedFilename;
    itemToAdd.duration = item.duration;
    itemToAdd.endTime = item.duration;
    itemToAdd.frameRate = item.frameRate;
    itemToAdd.videoSource = item.source;
    // TODO: transparency
    itemToAdd.startTime = 0;
    itemToAdd.fileExtension = item.extension;
    itemToAdd.slideDuration = item.duration;

    if (MULTI_MEDIA_UPLOAD_SOURCES.includes(item.source)) {
      itemToAdd.isMuted = false;
    }
    itemToAdd.uid = uid;

    const {slidesOrder, slidesHashMap} = activeItem.slides.toObject();

    const visuals = {};
    if (activeSlide.gitype === ITEM_TYPE.VIDEOSLIDE) {
      this.copyVisuals(visuals, activeSlide);
    }

    for (let index = 0; index < slidesOrder.length; index += 1) {
      if (slidesOrder[index] === activeSlide.uid) {
        slidesOrder[index] = uid;
      }
    }

    slidesHashMap[uid] = {
      ...visuals,
      gitype: ITEM_TYPE.VIDEOSLIDE,
      videoSource: itemToAdd.fileExtension,
      fileExtension: itemToAdd.fileExtension,
      hashedFilename: itemToAdd.hashedFilename,
      duration: itemToAdd.duration,
      frameRate: itemToAdd.frameRate,
      uid: itemToAdd.uid,
      slideDuration: itemToAdd.slideDuration,
    } as SlideItemObject;

    delete slidesHashMap[activeSlide.uid];

    showLoading('replaceWithVideoSlide');
    activeItem
      .updateFromObject(
        {
          slides: {
            slidesOrder,
            slidesHashMap,
          },
        },
        {checkForDurationUpdate: true}
      )
      .then((): void => {})
      .catch((): void => {})
      .finally((): void => {
        hideLoading('replaceWithVideoSlide');
      });
  }

  public async replaceSlideWithImageSlide(item: ImageData | TempImageData, activeSlide: ImageSlideItem | VideoSlideItem, activeItem: SlideshowItem): Promise<void> {
    if (item.source === ImageItemSource.GETTY && this.page.poster.hasMaxNumberOfGettyImages()) {
      if (!(activeSlide.isImageSlide() && activeSlide.imageSource === ImageItemSource.GETTY)) {
        openGettyImageLimitReachedMessageModal();
      }
      return;
    }
    const itemToAdd: Partial<ImageSlideItemObject> = {};
    const uid = 'uid' in item ? item.uid : uuidv4();
    itemToAdd.imageSource = item.source;
    itemToAdd.uid = uid;

    const {slidesOrder, slidesHashMap} = activeItem.slides.toObject();

    const visuals = {};
    if (activeSlide.gitype === ITEM_TYPE.IMAGESLIDE) {
      this.copyVisuals(visuals, activeSlide);
    }

    for (let index = 0; index < slidesOrder.length; index += 1) {
      if (slidesOrder[index] === activeSlide.uid) {
        slidesOrder[index] = uid;
      }
    }
    slidesHashMap[uid] = {
      ...visuals,
      gitype: ITEM_TYPE.IMAGESLIDE,
      imageSource: itemToAdd.imageSource,
      uid,
      slideDuration: DEFAULT_SLIDE_DURATION,
    } as SlideItemObject;

    if (isTempImageData(item)) {
      slidesHashMap[uid] = {
        ...slidesHashMap[uid],
        uploadingImageData: {
          tempUploadingImageUID: uid,
          dataUrl: item.dataUrl,
          uploadStartTime: new Date().getTime(),
        },
      };
    } else {
      slidesHashMap[uid] = {
        ...slidesHashMap[uid],
        fileExtension: item.extension,
        hashedFilename: item.hashedFilename,
      };
    }

    delete slidesHashMap[activeSlide.uid];

    showLoading('replaceWithImageSlide');
    activeItem
      .updateFromObject({
        slides: {
          slidesOrder,
          slidesHashMap,
        },
      })
      .then((): void => {})
      .catch((): void => {})
      .finally((): void => {
        hideLoading('replaceWithImageSlide');
      });
  }

  private copyVisuals(itemToAdd: DeepPartial<ImageItemObject | VideoItemObject>, activeItem: ImageItem | VideoItem): void {
    itemToAdd.border = activeItem.border.toObject();
    itemToAdd.effects = activeItem.effects.toObject();
    itemToAdd.lockMovement = activeItem.lockMovement;
    itemToAdd.aura = activeItem.aura.toObject();
    itemToAdd.alpha = activeItem.alpha;
  }

  private copyMaskingItems(itemToAdd: DeepPartial<ImageItemObject>, itemToAddWidth: number, itemToAddHeight: number, activeItem: ImageItem): void {
    if (!activeItem.masking.maskingItem || activeItem.masking.maskingItem.isFreehand()) {
      return;
    }

    itemToAdd.masking = {
      ...activeItem.masking.toObject(),
      maskingItem: this.getCopiedMaskingItemObject(activeItem.masking.maskingItem, itemToAddWidth, itemToAddHeight),
    };
  }

  private getCopiedMaskingItemObject(maskingItem: MaskingShape | MaskingText, itemToAddWidth: number, itemToAddHeight: number): MaskingShapeObject | MaskingTextObject {
    const heightRatio = itemToAddHeight / maskingItem.imageHeight;
    const widthRatio = itemToAddWidth / maskingItem.imageWidth;
    const smallerRatio = widthRatio < heightRatio ? widthRatio : heightRatio;

    return {
      ...maskingItem.toObject(),
      imageWidth: itemToAddWidth,
      imageHeight: itemToAddHeight,
      top: maskingItem.top * smallerRatio,
      left: maskingItem.left * smallerRatio,
      scaleX: maskingItem.scaleX * smallerRatio,
      scaleY: maskingItem.scaleY * smallerRatio,
    };
  }

  public async replaceWithImage(item: NewImageItemData | NewTempImageItemData, activeItem: ImageItem | VideoItem | ImageSlideItem | VideoSlideItem): Promise<void> {
    const zIndex = activeItem.getZIndex();
    const itemToAdd: DeepPartial<ImageItemObject> = prepImageItemObjectFromImageData(item);
    itemToAdd.y = activeItem.y;
    itemToAdd.x = activeItem.x;
    itemToAdd.rotation = activeItem.rotation;

    if ('extension' in item && item.extension === 'svg') {
      this.page.poster.deleteItemById(activeItem.uid, false);
      this.page.activeSelection?.clearSelection();
      void this.page.items.addItems.addImageItem(item, itemToAdd, {
        zIndex,
      });
      return;
    }

    let replacingAspectRatio = 0;
    if (activeItem.scaleX) {
      replacingAspectRatio = (activeItem.width * activeItem.scaleX) / (activeItem.height * activeItem.scaleY);
    } else {
      replacingAspectRatio = activeItem.width / activeItem.height;
    }

    if (activeItem.isImage()) {
      this.copyVisuals(itemToAdd, activeItem);
    }

    if (item) showLoading('LoadingImage');
    let imageSource;
    if ('hashedFilename' in item && 'extension' in item) {
      imageSource = getImageScreenURL(item.hashedFilename, item.extension, item.source);
    } else if (item.dataUrl) {
      imageSource = item.dataUrl;
    }
    if (!imageSource) {
      console.error('no image source found');
      return;
    }
    loadImageAsync(imageSource)
      .then(async (img) => {
        this.page.poster.deleteItemById(activeItem.uid, false);
        this.page.activeSelection?.clearSelection();

        const uid = 'uid' in item && item.uid ? item.uid : uuidv4();

        let cropOnAdd = false;
        let maskOnAdd = false;
        if (activeItem.isImage()) {
          if (replacingAspectRatio !== img.width / img.height) {
            cropOnAdd = true;
          }

          if (activeItem.masking.maskingItem && !activeItem.masking.maskingItem.isFreehand()) {
            this.copyMaskingItems(itemToAdd, img.width, img.height, activeItem);
            cropOnAdd = false;
            maskOnAdd = true;
          }
        }

        if (cropOnAdd) {
          if (item) hideLoading('LoadingImage');
          const cropperPanelResponse = await this.openCropModalAfterReplace(item, replacingAspectRatio);
          if (item) showLoading('LoadingImage');
          if (cropperPanelResponse) {
            itemToAdd.cropData = {
              cropped: true,
              ...cropperPanelResponse.cropData,
            };
            itemToAdd.removeBackground = {
              isBackgroundRemoved: cropperPanelResponse.isBackgroundRemoved,
            };
          }
        }

        await this.page.items.addItems.addImageItem(
          item,
          {uid, ...itemToAdd},
          {
            zIndex,
          }
        );

        if (maskOnAdd) {
          await this.openMaskingModalAfterReplace(uid);
        }
      })
      .catch(() => {
        this.errorModalWhileReplacingItem();
      })
      .finally(() => {
        hideLoading('LoadingImage');
      });
  }

  private async openMaskingModalAfterReplace(itemUID: string): Promise<void> {
    const addedItem = window.posterEditor?.whiteboard?.getItemForId(itemUID) as ImageItem;
    await onOpenMaskingModal(addedItem);
  }

  private async openCropModalAfterReplace(addedItem: NewImageItemData | NewTempImageItemData, aspectRatio: number): Promise<CropperPanelApplyResponse | undefined> {
    return new Promise((resolve) => {
      openCropperModal({
        onApply: resolve,
        onClose: (): void => {
          resolve(undefined);
        },
        aspectRatio,
        dataUrl: 'dataUrl' in addedItem ? addedItem.dataUrl : undefined,
        imageHashedFilename: 'hashedFilename' in addedItem ? addedItem.hashedFilename : undefined,
        imageSource: addedItem.source,
        showCancelButton: true,
        showRemoveBackgroundOption: true,
      });
    });
  }

  public async replaceWithVideo(item: VideoData, activeItem: ImageItem | VideoItem | ImageSlideItem | VideoSlideItem): Promise<void> {
    const zIndex = activeItem.getZIndex();
    const itemToAdd: Partial<VideoItemObject> = prepVideoItemObjectFromVideoData(item);
    itemToAdd.x = activeItem.x;
    itemToAdd.y = activeItem.y;
    itemToAdd.rotation = activeItem.rotation;

    showLoading('loadingVideo');
    const fileNameForVideo = getFilenameForVideo(item.hashedFilename, item.extension, this.page.poster.isHighRes, item.hasTransparency);
    const videoSource = getRepoVideoURL(fileNameForVideo, this.page.poster.isHighRes);

    if (activeItem.gitype === ITEM_TYPE.VIDEO) {
      this.copyVisuals(itemToAdd, activeItem);
    }

    loadVideo(videoSource)
      .then((video): void => {
        itemToAdd.width = video.width;
        itemToAdd.height = video.height;

        this.page.poster.deleteItemById(activeItem.uid, false);
        this.page.activeSelection?.clearSelection();
        void this.page.items.addItems.addVideoItem(item, itemToAdd, {zIndex});
      })
      .catch(() => {
        this.errorModalWhileReplacingItem();
      })
      .finally(() => {
        hideLoading('loadingVideo');
      });
  }

  public errorModalWhileReplacingItem(): void {
    openErrorModal({
      message: window.i18next.t('pmwjs_general_ajax_error'),
      log: false,
    });
  }
}

export const onReplaceMedia = (itemToReplaceWith: ItemData): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  const activeItem = currentPage.getSelectedItems();

  if (!activeItem || activeItem.length !== 1) {
    return;
  }

  if (!activeItem[0].isImage() && !activeItem[0].isVideo()) {
    return;
  }

  void currentPage.items.replaceItems.replaceMedia(itemToReplaceWith, activeItem[0]);
};

export const onReplaceMediaSlide = (itemToReplaceWith: ItemData): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  const activeItems = currentPage.getSelectedItems();

  if (!activeItems || activeItems.length !== 1) {
    return;
  }

  const activeItem = activeItems[0];

  if (!activeItem.isSlideshow()) {
    return;
  }

  const activeSlide = activeItem.getSelectedSlide();

  if (!activeSlide || !activeSlide.isMediaSlide()) {
    return;
  }

  void currentPage.items.replaceItems.replaceMediaSlide(itemToReplaceWith, activeSlide, activeItem);
};
