import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';

const PANEL_ID = 'replace-media-modal-panel';

export function ReplaceMediaModal(): ReactElement {
  return (
    <Modal modalId={MODALS_IDS.REPLACE_MEDIA_MODAL} panelId={PANEL_ID} modalHeight="fit-content" modalWidth="100%">
      <LazyPanel
        panelDirectoryName="replace-media-panel"
        panelProps={{
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openReplaceMediaModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.REPLACE_MEDIA_MODAL,
      panelProps: {},
    })
  );
};
