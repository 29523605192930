import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getSortedKeysOnValue} from '@Utils/object.util';
import type {Props as ReactModalProps} from 'react-modal';
import {POSTER_PUSH_MODAL_IDS} from '@Components/poster-editor/components/post-poster-modal-spacer/post-poster-modal-spacer.types';
import type {RootState} from '@/store';

export interface ControllableModalStorage extends Omit<ReactModalProps, 'appElement' | 'isOpen'> {
  panelProps?: Record<string, any>;
  animateModalClose?: boolean;
  isDismissible?: boolean;
  isOpen?: boolean;
  order?: number;
}

interface OpenPanelInModalProps {
  panelProps: Record<string, any>;
  isDismissible?: boolean;
  modalId: string;
}

export interface GetOpenedModalResponse {
  modalData: ModalStorage;
  modalId: string;
}

export interface ModalStorage extends ControllableModalStorage {
  isOpen: boolean;
  panelProps: Record<string, any>;
  isDismissible: boolean;
  animateModalClose: boolean;
  order: number;
  height?: number;
}

interface UpdateModalHeightActions {
  modalId: string;
  height: number;
}

interface StateProps {
  modalsHashmap: Record<string, ModalStorage>;
  keyboardShift: number;
}

const initialState: StateProps = {
  modalsHashmap: {},
  keyboardShift: 0,
};

export const modalContainerSlice = createSlice({
  name: 'modalContainer',
  initialState,
  reducers: {
    openPanelInModal: (state, action: PayloadAction<OpenPanelInModalProps>) => {
      const {modalId} = action.payload;

      state.modalsHashmap[modalId] = {
        ...state.modalsHashmap[modalId],
        order: 999,
        isDismissible: action.payload.isDismissible !== undefined ? action.payload.isDismissible : true,
        animateModalClose: true,
        isOpen: true,
        panelProps: {...action.payload.panelProps},
      };
      updateOrderOfModals(state.modalsHashmap);
    },
    closeModal: (state, action: PayloadAction<string>) => {
      const modalData = state.modalsHashmap[action.payload];
      if (modalData) {
        modalData.isOpen = false;
      }
    },
    closeAllModals: (state) => {
      for (const [, modalData] of Object.entries(state.modalsHashmap)) {
        modalData.isOpen = false;
      }
    },
    updateHeightForModal: (state, action: PayloadAction<UpdateModalHeightActions>) => {
      state.modalsHashmap[action.payload.modalId].height = action.payload.height;
    },
    updateKeyboardShift: (state, action: PayloadAction<number>) => {
      state.keyboardShift = action.payload;
    },
  },
});

const updateOrderOfModals = (modalsHashmap: Record<string, ModalStorage>): void => {
  const modalsOrder: Record<string, number> = {};

  for (const [modalId, modalData] of Object.entries(modalsHashmap)) {
    modalsOrder[modalId] = modalData.order;
  }

  const sortedModalIds = getSortedKeysOnValue(modalsOrder);

  for (let i = 0; i < sortedModalIds.length; i++) {
    modalsHashmap[sortedModalIds[i]].order = i + 1;
  }
};

export const checkIfModalRootShouldHideFromStore = (state: RootState, panelId: string): boolean => {
  const isThisPanelHidden = !!state.panels.panelHashmap[panelId]?.isHidden;
  if (isThisPanelHidden) {
    return !!state.panels.panelHashmap[panelId]?.hideOnClose;
  }

  return false;
};

export const {openPanelInModal, closeModal, closeAllModals, updateHeightForModal, updateKeyboardShift} = modalContainerSlice.actions;
export const modalContainerReducer = modalContainerSlice.reducer;

export const isModalOpen = (state: RootState): boolean => {
  for (const [, modalData] of Object.entries(state.modals.modalsHashmap)) {
    if (modalData.isOpen) {
      return true;
    }
  }
  return false;
};

export const isPosterPushModalOpen = (state: RootState): boolean => {
  for (const [modalId, modalData] of Object.entries(state.modals.modalsHashmap)) {
    if (modalData.isOpen && !POSTER_PUSH_MODAL_IDS[modalId] && modalData.panelProps?.hideOnClose !== true) {
      return true;
    }
  }
  return false;
};

export const getOpenedModal = (state: RootState): GetOpenedModalResponse | undefined => {
  for (const [modalId, modalData] of Object.entries(state.modals.modalsHashmap)) {
    if (modalData.isOpen) {
      return {
        modalId,
        modalData,
      };
    }
  }
  return undefined;
};
