const numberFormatter = Intl.NumberFormat(navigator.language);

export const formatNumber = (toFormat: number): string => {
  return numberFormatter.format(toFormat);
};

export const formatNumberInUSD = (toFormat: number): string => {
  return window.PMW.util.currencyFormat(toFormat, window.PMW.CURRENCY_CODE_USD);
};

export const formatNumberInCurrency = (toFormat: number, currencyCode: string): string => {
  return window.PMW.util.currencyFormat(toFormat, currencyCode);
};

export const getRandomNumberInRange = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const formatToNearestThousand = (val: number): string => {
  if (val > 999) {
    const temp = val / 1000;
    return `${temp.toFixed(0)}k`;
  }

  return String(val);
};

export const formatToThousand = (val: number): string => {
  if (Math.abs(val) > 999) {
    const temp = Math.floor(val / 100) / 10;
    return `${temp}k`;
  }

  return String(val);
};
