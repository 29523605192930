import type {ReactElement, UIEvent} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {noop} from '@Utils/general.util';
import {CropOption, ResizeOption} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import {useDesignIdToResize, useSocialMediaWizardPost} from '@Components/social-media/social-media.hooks';
import type {SocialMediaResizeAjaxParams} from '@Components/social-media/social-media.types';
import {MultiGraphicDesignSelectorModes} from '@Components/social-media/social-media.types';
import {getResizeConfigForGATracking, getResizeOptionsConfigFromResizeOptions} from '@Libraries/social-media-wizard-library';
import {getSocialPlatformName, makeSocialMediaResizeAjaxCall} from '@Libraries/social-media';
import {getPosterPreviewURL} from '@Libraries/poster-library';
import {SocialMediaResizeOptionsPreview} from '@Components/social-media-wizard/components/social-media-resize-options/components/social-media-resize-options-preview';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {TabItems, TabKey} from '@Components/tabs';
import {Tabs, TabSliderType, TabsThemes} from '@Components/tabs';
import {Text, TextSize} from '@Components/text';
import {ColorPickerBox} from '@Components/color-picker-box';
import {Panel, PanelFooter, PanelFooterPrimaryDefaultAction, PanelFooterSecondaryDefaultAction, PanelHeader} from '@Components/panel';
import {Size, Type} from '@Components/button';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import useWindowSize from '@Hooks/useWindowSize';
import {MOBILE_MODAL_WIDTH_THRESHOLD} from '@Components/modal';
import {isModalOpen} from '@Components/modal-container';
import {updateMultiGraphicDesignSelectorMode} from '@Components/social-media-wizard/social-media-wizard-reducer';
import ErrorPanel from '@Panels/error-panel';
import {useIsSmallerThanBreakpointWidth} from '@Hooks/useIsSmallerThanBreakpoint';
import {SCREEN_BREAKPOINTS} from '@Libraries/responsiveness-library';
import {useWizardDesignsGraphicsMeta} from '@Components/social-media-wizard/social-media-wizard.hooks';
import type {PosterHashedId} from '@Components/social-media/publish-params.types';
import {GA4EventName, GA4EventParamName, trackPublishingGA4Events} from '@Libraries/ga-events';
import {PlatformMediaType} from '@Components/social-media/post.vo';
import styles from './social-media-resize-panel.module.scss';
import {useAppDispatch} from '@/hooks';

export const SOCIAL_MEDIA_RESIZE_PANEL_ID = 'social-media-resize-panel';

export interface SocialMediaResizePanelProps {
  panelId?: string;
  account: SocialAccount;
  onResizeCompleted: (previewUrl: string, posterId: PosterHashedId) => void;
  onResizeError?: (posterId: PosterHashedId) => void;
  onCancelResize?: VoidFunction;
  onDoneClicked?: (posterId: PosterHashedId) => void;
  onCloseClicked?: VoidFunction;
  platformMediaType?: PlatformMediaType;
}

function SocialMediaResizePanel({
  panelId = SOCIAL_MEDIA_RESIZE_PANEL_ID,
  account,
  onResizeCompleted,
  onResizeError = noop,
  onDoneClicked = noop,
  platformMediaType = PlatformMediaType.POST,
  ...props
}: SocialMediaResizePanelProps): ReactElement | null {
  const [selectedOption, setSelectedOption] = useState(ResizeOption.FILL);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [cropOption, setCropOption] = useState(CropOption.CROP_TOP);
  const [fillColor, setFillColor] = useState('#000000');
  const [isPortrait, setIsPortrait] = useState(false);
  const CROP_RESIZE_TABS = 'social-media-resize-crop-option-tabs';
  const RESIZE_PANEL_FOOTER_PRIMARY_ACTION_KEY = 'resize-panel-primary-action-footer-key';
  const RESIZE_PANEL_FOOTER_SECONDARY_ACTION_KEY = 'resize-panel-secondary-action-footer-key';
  const designId = useDesignIdToResize();
  const graphicsMetaForAllDesigns = useWizardDesignsGraphicsMeta();
  const graphicsMetaForSelectedDesign = designId ? graphicsMetaForAllDesigns[designId] : null;
  const wizardPost = useSocialMediaWizardPost();
  const {windowWidth} = useWindowSize();
  const dispatch = useAppDispatch();
  const shouldShowMobileModal = useIsSmallerThanBreakpointWidth(SCREEN_BREAKPOINTS.MOBILE_MODAL_ACTIVATION_BREAKPONT);
  const onClose = props.onCloseClicked ?? useDefaultPanelClose(panelId);
  const userHasOnlyOneDesignSelected = Object.keys(graphicsMetaForAllDesigns).length === 1;

  const onBack = (): void => {
    if (userHasOnlyOneDesignSelected) {
      onClose();
      return;
    }

    setPanelModeToSelectDesign();
  };

  const setPanelModeToSelectDesign = (): void => {
    dispatch(updateMultiGraphicDesignSelectorMode(MultiGraphicDesignSelectorModes.Select));
  };

  const isMobileModal = (): boolean => {
    return windowWidth < MOBILE_MODAL_WIDTH_THRESHOLD || isModalOpen();
  };

  const doAddModalClasses = isMobileModal();
  const getTextForCropOptions = useCallback((givenCropOption: CropOption): string => {
    if (givenCropOption === CropOption.CROP_TOP) {
      return window.i18next.t('pmwjs_top');
    }
    if (givenCropOption === CropOption.CROP_CENTER) {
      return window.i18next.t('pmwjs_center');
    }
    if (givenCropOption === CropOption.CROP_BOTTOM) {
      return window.i18next.t('pmwjs_bottom');
    }
    return '';
  }, []);

  const getCropOptionFromText = useCallback((cropOptionText: string): CropOption => {
    if (cropOptionText === window.i18next.t('pmwjs_top')) {
      return CropOption.CROP_TOP;
    }
    if (cropOptionText === window.i18next.t('pmwjs_center')) {
      return CropOption.CROP_CENTER;
    }
    if (cropOptionText === window.i18next.t('pmwjs_bottom')) {
      return CropOption.CROP_BOTTOM;
    }
    return CropOption.CROP_TOP;
  }, []);

  const onScroll = (e: UIEvent): void => {
    const container = e.target as HTMLElement;

    setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
    setShowLeftArrow(container.scrollLeft > 0);
  };

  useEffect(() => {
    const container = document.getElementById('ts-resize-image-scroller');
    if (container) {
      setShowRightArrow(container.scrollWidth > container.clientWidth);
    }
  }, []);

  const scrollRight = (): void => {
    const container = document.getElementById('ts-resize-image-scroller');
    if (container) {
      container.scrollBy({
        left: 50,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = (): void => {
    const container = document.getElementById('ts-resize-image-scroller');
    if (container) {
      container.scrollBy({
        left: -50,
        behavior: 'smooth',
      });
    }
  };

  const sendDesignForResize = async (designIdToResize: string): Promise<void> => {
    if (!designIdToResize) {
      throw new Error('No design id passed to send design for resize');
    }

    const data: SocialMediaResizeAjaxParams = {
      posterId: designIdToResize,
      idPost: wizardPost?.id ?? '',
      resizeType: getResizeOptionsConfigFromResizeOptions(selectedOption, cropOption),
      socialMediaPlatform: account.type,
      socialAccountId: account.id,
      isVideo: graphicsMetaForAllDesigns[designIdToResize].isVideo,
      platformMediaType,
    };

    if (selectedOption === ResizeOption.FILL) {
      Object.assign(data, {fillColor});
    }

    const isDesignAVideo = graphicsMetaForAllDesigns[designIdToResize].isVideo;

    makeSocialMediaResizeAjaxCall(data, isDesignAVideo)
      .then((graphicUrl: string): void => {
        return onResizeCompleted(graphicUrl, designIdToResize);
      })
      .catch((error): void => {
        onResizeError(designIdToResize);
        return console.error('Error while resizing', error);
      });
  };

  const previewAssetUrl: string = useMemo(() => {
    if (!designId) {
      return '';
    }

    return getPosterPreviewURL(designId);
  }, []);

  const resizeOptionsThumbPreviews = useMemo(() => {
    if (!designId) {
      console.log('No designId found');
      return <div />;
    }

    const resizeOptionsKeys = Object.keys(ResizeOption).filter((v) => {
      return !Number.isNaN(Number(v));
    });

    const isDesignAVideo = graphicsMetaForAllDesigns[designId].isVideo;

    return (
      <div className={`${styles.panelImageScroller}`} onScroll={onScroll} id="ts-resize-image-scroller">
        {resizeOptionsKeys.map((element, index) => {
          const resizeOption: ResizeOption = (index + 1) as ResizeOption;
          return (
            <SocialMediaResizeOptionsPreview
              key={element}
              onDesignClick={(selectedDesign: ResizeOption): void => {
                setSelectedOption(selectedDesign);
              }}
              previewResizeType={resizeOption}
              selected={selectedOption === resizeOption}
              imageSource={previewAssetUrl}
              fillColor={fillColor}
              isVideo={isDesignAVideo}
            />
          );
        })}
      </div>
    );
  }, [selectedOption, fillColor]);

  const getLeftArrow = useMemo((): ReactElement | null => {
    if (!showLeftArrow) {
      return null;
    }
    return (
      <div className={styles.scrollLeftArrowDiv}>
        <Icon icon="icon-caret-left" size={IconSize.SIZE_ICON_20} type={IconType.SECONDARY} shape={IconShape.CIRCLE} isUsedAsButton onClick={scrollLeft} />
      </div>
    );
  }, [showLeftArrow]);

  const getRightArrow = useMemo((): ReactElement | null => {
    if (!showRightArrow) {
      return null;
    }

    return (
      <div className={styles.scrollRightArrowDiv}>
        <Icon icon="icon-caret-right" size={IconSize.SIZE_ICON_20} type={IconType.SECONDARY} shape={IconShape.CIRCLE} isUsedAsButton onClick={scrollRight} />
      </div>
    );
  }, [showRightArrow]);

  const getTabsForCrop = useMemo((): TabItems => {
    const tabsToReturn: TabItems = [];
    const resizeOptionsKeys = Object.keys(CropOption).filter((v) => {
      return Number.isNaN(Number(v));
    });

    resizeOptionsKeys.forEach((_, index: number): void => {
      tabsToReturn.push({
        tabKey: getTextForCropOptions((index + 1) as CropOption),
        text: getTextForCropOptions((index + 1) as CropOption),
      });
    });

    return tabsToReturn;
  }, []);

  const getResizeOptionDetails = useMemo((): ReactElement | null => {
    if (selectedOption === ResizeOption.FILL) {
      return (
        <div className={`${styles.resizeOptionDetails} spacing-m-t-5`}>
          <Text val={window.i18next.t('pmwjs_fill_color')} size={TextSize.XSMALL} />
          <ColorPickerBox dropdownClassName={styles.colorPicker} onChangeEnded={setFillColor} color={fillColor} />
        </div>
      );
    }

    if (selectedOption === ResizeOption.CROP) {
      return (
        <div className={`${styles.resizeOptionDetails} spacing-m-t-3`}>
          <Text val={window.i18next.t('pmwjs_position')} className="spacing-m-t-2" size={TextSize.XSMALL} />
          <Tabs
            id={CROP_RESIZE_TABS}
            tabItems={getTabsForCrop}
            selectedTab={getTextForCropOptions(cropOption)}
            sliderType={TabSliderType.SLIDER_BLOCK}
            theme={TabsThemes.PRIMARY_LIGHT}
            onTabClick={(tabKey: TabKey): void => {
              setCropOption(getCropOptionFromText(tabKey));
            }}
          />
        </div>
      );
    }

    return null;
  }, [selectedOption]);

  const getPanelContent = (): ReactElement => {
    return (
      <div className="flex-row-justify-between">
        <div className={`${styles.panelContentClasses} ${shouldShowMobileModal ? 'spacing-p-t-2 spacing-p-b-4 _full-width' : `spacing-p-t-6 ${styles.halfWidth}`}`}>
          <div className={styles.resizeOptionsContainer} data-showrightarrow={showRightArrow ? '1' : '0'} data-showleftarrow={showLeftArrow ? '1' : '0'}>
            {getLeftArrow}
            {resizeOptionsThumbPreviews}
            {getRightArrow}
          </div>
          {getResizeOptionDetails}
        </div>
        {getResizeOptionsPreview()}
      </div>
    );
  };

  const getResizeTypeClasses = (): string => {
    if (selectedOption === ResizeOption.BLUR) {
      return styles.blur;
    }

    if (selectedOption === ResizeOption.STRETCH) {
      return styles.stretch;
    }

    if (selectedOption === ResizeOption.FILL) {
      return styles.fill;
    }

    return styles.crop;
  };

  const getCropOptionClass = (): string => {
    if (!(selectedOption === ResizeOption.CROP)) {
      return '';
    }

    switch (cropOption) {
      case CropOption.CROP_BOTTOM:
        return styles.objectPositionBottom;
      case CropOption.CROP_CENTER:
        return styles.objectPositionCenter;
      case CropOption.CROP_TOP:
        return styles.objectPositionTop;
      default:
        return '';
    }
  };

  useEffect(() => {
    const image = new Image();
    image.src = previewAssetUrl;

    image.onload = (): void => {
      const aspectRatio = image.width / image.height;
      setIsPortrait(aspectRatio < 1);
    };
  }, [previewAssetUrl]);

  const isFillSelected = (): boolean => {
    return selectedOption === ResizeOption.FILL;
  };

  const isBlurSelected = (): boolean => {
    return selectedOption === ResizeOption.BLUR;
  };

  const trackResizeGAEvent = (): void => {
    const resizeOptionsConfig = getResizeOptionsConfigFromResizeOptions(selectedOption, cropOption);
    const resizeConfigName = getResizeConfigForGATracking(resizeOptionsConfig);
    trackPublishingGA4Events(GA4EventName.SOCIALWIZARD_RESIZEOPTIONS, {
      [GA4EventParamName.PLATFORM]: getSocialPlatformName(account.type),
      [GA4EventParamName.TYPE]: resizeConfigName,
    });
  };

  const startUserTriggeredResize = (): void => {
    if (!designId) {
      throw new Error('No design selected for resize');
    }

    trackResizeGAEvent();
    void sendDesignForResize(designId);
    onDoneClicked(designId);
    onClose();
  };

  if (!designId) {
    console.error('No design Id found');
    return <ErrorPanel />;
  }

  const getResizeOptionsPreview = (): ReactElement | null => {
    if (shouldShowMobileModal) {
      return null;
    }

    return (
      <div className={`${styles.panelContentPreview} spacing-p-5`}>
        <div className={`${styles.previewContainer}`} style={isFillSelected() ? {background: fillColor} : undefined}>
          <img
            className={`${styles.previewImage} ${getResizeTypeClasses()}  ${getCropOptionClass()} ${isPortrait ? styles.inheritHeight : ''}`}
            src={previewAssetUrl}
            alt="graphic"
          />
          {isBlurSelected() ? <div className={styles.blurredImage} style={{background: `url(${previewAssetUrl})`}} /> : null}
        </div>
      </div>
    );
  };

  const getPanelHeader = useMemo((): ReactElement => {
    return (
      <PanelHeader
        className={styles.panelHeader}
        title={window.i18next.t('pmwjs_resize_x', {designName: graphicsMetaForSelectedDesign?.name})}
        onClose={onClose}
        showBackButton
        onBackButton={onBack}
        isOpenedInModal
      />
    );
  }, []);

  const getFooterPrimaryAction = (): ReactElement[] => {
    return [
      <PanelFooterPrimaryDefaultAction
        className={styles.resizePrimaryButton}
        key={RESIZE_PANEL_FOOTER_PRIMARY_ACTION_KEY}
        text={window.i18next.t('pmwjs_done')}
        type={Type.PRIMARY}
        onClick={startUserTriggeredResize}
        size={Size.SMALL}
        disabled={!designId}
      />,
    ];
  };

  const getFooterSecondaryAction = (): ReactElement[] => {
    return [
      <PanelFooterSecondaryDefaultAction
        size={Size.SMALL}
        key={RESIZE_PANEL_FOOTER_SECONDARY_ACTION_KEY}
        text={window.i18next.t('pmwjs_cancel')}
        type={Type.SECONDARY}
        onClick={onBack}
      />,
    ];
  };

  const getPanelFooter = useMemo((): ReactElement => {
    return (
      <PanelFooter
        className={`${styles.panelFooter} ${doAddModalClasses ? styles.modalPanelFooter : ''}`}
        primaryActions={getFooterPrimaryAction()}
        secondaryActions={getFooterSecondaryAction()}
      />
    );
  }, [fillColor, selectedOption]);

  return (
    <Panel
      panelBodyClassName={`${styles.panelBodyClasses}`}
      className={styles.panelClasses}
      panelHeader={getPanelHeader}
      panelContent={getPanelContent()}
      panelFooter={getPanelFooter}
    />
  );
}

export default SocialMediaResizePanel;
