import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {MessagePanelProps} from '@Panels/message-panel';
import {PanelFooter, PanelFooterPrimaryDefaultAction, PanelHeader} from '@Components/panel';
import {ModalFooterPrimaryAction} from '@Components/modal-deprecated/components/modal-footer-primary-action';
import {MODALS_IDS} from '@Components/modal-container';
import {useDispatch} from 'react-redux';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'message-modal-panel';

interface MessageModalCtaButton {
  text: string;

  onClick(): void;

  classes?: string;
}

export interface MessageModalProps extends MessagePanelProps {
  height?: string;
  width?: string;
  title?: string;

  onClose?(): void | Promise<void>;

  ctaButton?: MessageModalCtaButton;
}

export function MessageModal(): ReactElement {
  const panelProps = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.MESSAGE_MODAL].panelProps as MessageModalProps;
  });
  const dispatch = useDispatch();

  const height = panelProps.height ?? '445px';
  const width = panelProps.width ?? '540px';

  const onModalClose = async (): Promise<void> => {
    dispatch(closeModal(MODALS_IDS.MESSAGE_MODAL));
    if (panelProps.onClose) {
      await panelProps.onClose();
    }
  };

  let primaryAction = <PanelFooterPrimaryDefaultAction key="btn-close" text={window.i18next.t('pmwjs_close')} onClick={onModalClose} />;
  if (panelProps.ctaButton) {
    primaryAction = (
      <ModalFooterPrimaryAction key="cta" customClasses={panelProps.ctaButton.classes ?? ''} text={panelProps.ctaButton.text} onClick={panelProps.ctaButton?.onClick} />
    );
  }

  return (
    <Modal modalId={MODALS_IDS.MESSAGE_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="message-panel"
        panelProps={
          {
            ...panelProps,
            header: panelProps.header ?? <PanelHeader title={panelProps.title} onClose={onModalClose} />,
            footer: panelProps.footer ?? <PanelFooter fullWidthPrimaryActions primaryActions={[primaryAction]} />,
            panelId: PANEL_ID,
          } as MessagePanelProps
        }
      />
    </Modal>
  );
}

export const openMessageModal = (props: MessageModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.MESSAGE_MODAL,
      panelProps: props,
    })
  );
};

export const closeMessageModal = (): void => {
  window.PMW.redux.store.dispatch(closeModal(MODALS_IDS.MESSAGE_MODAL));
};
